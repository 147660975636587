import { AssetEntry, AssetLoaderStrategy } from "../components/AsssetsLoader";
import { BASE_URL } from "./config";
import { isIOS } from "./functions";
import { images } from "./images";
import { videos } from "./videos";

export * from "./images";
export * from "./videos";

export enum Quality {
  low = "low",
  medium = "medium",
  high = "high",
}

function convertImageUrl(asset: AssetEntry, quality: Quality): AssetEntry {
  switch (quality) {
    case Quality.low:
    case Quality.medium:
      return {
        ...asset,
        path: asset.path.replace("/images", "/compressed_images"),
      };
    default:
      return asset;
  }
}

function convertVideoUrl(asset: AssetEntry, quality: Quality): AssetEntry {
  switch (quality) {
    case Quality.low:
    case Quality.medium:
      return {
        ...asset,
        path: asset.path.replace("/videos", "/compressed_videos"),
      };
    default:
      return asset;
  }
}

export function getAssetLoaderStrategy(
  orElse: AssetLoaderStrategy
): AssetLoaderStrategy {
  const urlParams = new URLSearchParams(window.location.search);
  const strategy = urlParams.get("strategy");
  switch (strategy) {
    case "cacheBefore":
      return "cacheBefore";
    case "cacheAfter":
      return "cacheAfter";
    case "noCache":
      return "noCache";
    default:
      return orElse;
  }
}

export function getQuality() {
  const urlParams = new URLSearchParams(window.location.search);
  // eslint-disable-next-line no-restricted-globals
  const isSmall = screen.width <= 960;
  const qualityBySize = isIOS() || isSmall ? Quality.low : Quality.high;
  return (urlParams.get("quality") as Quality | null) ?? qualityBySize;
}

export async function hasEnoughSpace() {
  if (!navigator.storage || !navigator.storage.estimate) {
    return null;
  }
  return navigator.storage
    .estimate()
    .then((estimate) => {
      const quota = estimate.quota ?? 0;
      const minGBytes = getQuality() === Quality.high ? 7 : 4;
      const minBytes = minGBytes * 1024 * 1024 * 1024;

      if (quota < minBytes) {
        return minBytes;
      } else {
        return null;
      }
    })
    .catch(() => null);
}

function getBaseUrl() {
  const allUrls = BASE_URL.split(",");
  const urlParams = new URLSearchParams(window.location.search);
  const mediaUrl = urlParams.get("mediaUrl");
  const res = allUrls[0];
  let decoded: string | undefined = undefined;
  if (mediaUrl != null) {
    try {
      const tmp_decoded = atob(mediaUrl);
      decoded = allUrls.find((url) => url === tmp_decoded);
    } catch (error) {}
  }
  return decoded ?? res;
}

export function getAssets() {
  const quality = getQuality();
  const baseUrl = getBaseUrl();
  let result = new Map<string, AssetEntry>();
  Object.entries(images(baseUrl)).forEach(([key, value]) =>
    result.set(key, convertImageUrl(value, quality))
  );
  Object.entries(videos(baseUrl)).forEach(([key, value]) =>
    result.set(key, convertVideoUrl(value, quality))
  );
  return result;
}
