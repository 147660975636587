/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  Grid,
  GridProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useSingleAsset, useAsset } from "../../components/AsssetsLoader";
import {
  InternalPage,
  ZIndexes,
} from "../../components/InternalPage/InternalPage";
import {
  useIsRouteVisible,
  useRouter,
} from "../../components/MainRouter/MainRouter";
import { ImageKeys, Quality, getQuality } from "../../utils/assets";
import { Routes } from "../../utils/routes";
import { useEffect, useMemo, useRef, useState } from "react";
import { TextCard } from "../../components/Card/TextCard";
import { Line } from "../../components/Atoms/Lines";
import { heightToWidth } from "../../utils/functions";
import {
  HorizontalGrid,
  VerticalGrid,
} from "../../components/HorizontalGrid/HorizontalGrid";
import { useWindowSize } from "@uidotdev/usehooks";
import { GenericCard } from "../../components/Card/GenericCard";
import { VideoKeys } from "../../utils/assets";
import { BioRepeelLogo } from "../../components/Logos/BioRepeelLogo";
import { ShowMore } from "../../components/Atoms/ShowMore";
import { KeyMessages } from "../../components/KeyMessages";
import { NewVerticalScrollableCard } from "../../components/Card/NewVerticalScrollableCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { initVisoWithoutRotation } from "../../redux/viso.reducer";
import { openModal } from "../../redux/modal.reducer";
import { BeforeAndAfter } from "../../components/Card/BeforeAndAfter";
import { setIntroViewed, useIsIntroViewed } from "../../redux/pages.reducer";
import { Colors } from "../../styles/theme";
import { focusInAnimation, transitionEnabled } from "../../constants";
import { useHandleIntro } from "./BeloteroIntroProdotti";
import { BackgroundIntro } from "../../components/BackgroundIntro";
import { PlayIcon } from "../../components/Icons/PlayIcon";
import { BiorepeelTessere } from "../../components/Icons/BiorepeelTessere";
import { Center } from "../elite/digital_marketing";

export function BioRepeelPage() {
  const isVisible = useIsRouteVisible(Routes.biorepeel);
  const { changeRoute } = useRouter();
  const dispatch = useAppDispatch();
  const biorepeel_sfondo = useSingleAsset<ImageKeys>("biorepeel_sfondo");

  if (!isVisible) return <></>;

  return (
    <InternalPage
      color="biorepeel"
      logoFill="black"
      backgroundColor="white"
      goUpper={() =>
        changeRoute(Routes.inostriprodotti, { showPrevious: false })
      }
      goBack={() => changeRoute(Routes.ultherapy, { showPrevious: false })}
      goNext={() => {
        dispatch(initVisoWithoutRotation());
        changeRoute(Routes.viso, { showPrevious: false });
      }}
      sectionHeader={
        <Header
          id="biorepeel"
          color="biorepeel"
          showEverywhere
          centered
          children={<BioRepeelLogo width={"20rem"} fill="black" />}
        />
      }
    >
      <BackgroundIntro id="biorepeel" type="img" src={biorepeel_sfondo} />
      <Inner id="biorepeel" children={<Core />} color="biorepeel" />
    </InternalPage>
  );
}
interface HeaderProps {
  id?: string;
  color: Colors;
  children?: GridProps["children"];
  showEverywhere?: boolean;
  centered?: boolean;
}

export function Header({
  id,
  children,
  color,
  showEverywhere,
  centered,
}: HeaderProps) {
  const isViewed = useAppSelector(
    (state) => state.pages.intro_viewed.find((e) => e === id) !== undefined
  );

  const { height } = useWindowSize();

  if (height === null) return <></>;

  if (centered) {
    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Line color={color} height={15} />
          <Grid item py={3} children={children} visibility={"hidden"} />
        </Grid>
        <Box
          position={"fixed"}
          top={!isViewed ? `${height / 2}px` : 15}
          left={0}
          width={"100%"}
          sx={{
            marginTop: !isViewed ? "-3rem" : 0,
            transform: isViewed ? "scale(1)" : "scale(2)",
            transition: `all 2s ease-out`,
          }}
        >
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            alignContent={"center"}
            width={"100%"}
          >
            <Grid item py={3} children={children} />
          </Grid>
        </Box>
      </>
    );
  } else {
    return isViewed || showEverywhere ? (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Line color={color} height={15} />
        <Grid item py={3} children={children} />
      </Grid>
    ) : (
      <></>
    );
  }
}

interface ShowMoreIntroProps {
  id: string;
  children: JSX.Element;
  color: Colors;
  videoKey?: string;
  logo?: JSX.Element;
}

export function Inner({
  id,
  children,
  color,
  videoKey,
  logo,
}: ShowMoreIntroProps) {
  const isViewed = useAppSelector(
    (state) => state.pages.intro_viewed.find((e) => e === id) !== undefined
  );
  const video = useSingleAsset<VideoKeys>(videoKey);
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const showMoreAreBottom = useMemo(() => {
    return video === undefined || getQuality() !== Quality.low;
  }, [video]);

  return (
    <>
      <Box
        width={"100%"}
        height={"100%"}
        children={children}
        visibility={isViewed ? "visible" : "hidden"}
      />
      {!isViewed &&
        (showMoreAreBottom ? (
          <Box
            position={"absolute"}
            bottom={0}
            zIndex={ZIndexes.everywhere}
            width={"100%"}
          >
            <Grid
              container
              direction="column"
              justifyContent={"flex-end"}
              p={4}
            >
              <Grid item>
                <Grid container direction={"row"} justifyContent={"center"}>
                  <ShowMore
                    color={color}
                    onClick={() => dispatch(setIntroViewed(id))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            position={"absolute"}
            left={40}
            top={0}
            zIndex={ZIndexes.everywhere}
            width={"50%"}
            height={"100%"}
          >
            <Grid
              container
              direction={"column"}
              width={"100%"}
              height={"100%"}
              justifyContent={"center"}
              alignContent={"center"}
              gap={6}
            >
              <Grid item children={logo} />
              <Grid item pt={6}>
                <ShowMore
                  ref={ref}
                  color={color}
                  width={"70%"}
                  text="Visualizza il video"
                  icon={<PlayIcon height={"2rem"} />}
                  onClick={() => {
                    dispatch(
                      openModal({
                        animated: false,
                        modalData: { type: "video", video: video! },
                        rect: ref.current!.getRect(),
                      })
                    );
                  }}
                />
              </Grid>
              <Grid item>
                <ShowMore
                  width={"70%"}
                  color={color}
                  onClick={() => dispatch(setIntroViewed(id))}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
    </>
  );
}

interface TopDescriptionProps {
  children: TypographyProps["children"];
  color: Colors;
  id?: string;
}
export function TopDescription(props: TopDescriptionProps) {
  const show = useIsIntroViewed(props.id);
  if (show || props.id === undefined)
    return (
      <Typography
        variant="body1"
        fontWeight={"bold"}
        textAlign={"center"}
        sx={{
          whiteSpace: "break-spaces",
          color: `${props.color}.main`,
          ...focusInAnimation,
        }}
        children={props.children}
      />
    );
  else {
    return <></>;
  }
}

function Core() {
  const sfondoGamma = useSingleAsset<ImageKeys>("biorepeel_sfondo_gamma");
  const show = useHandleIntro("biorepeel", "after");
  return (
    <>
      <Box position={"fixed"} top={0} left={0} width={"100%"} height={"100%"}>
        <VerticalGrid type="container" rows={["60%", "40%"]}>
          <VerticalGrid type="item">
            <VerticalGrid type="container" rows={["8rem", "5rem", "auto"]}>
              <VerticalGrid type="item" />
              <VerticalGrid type="item">
                <Grid container direction={"row"} justifyContent={"center"}>
                  <Grid item xs={5}>
                    <TopDescription id={Routes.biorepeel} color="biorepeel">
                      BioRePeelCl3® è l'evoluzione del peeling medicale, l'unica
                      formulazione bifasica con azione biostimolante e
                      rivitalizzante, per viso e corpo
                    </TopDescription>
                  </Grid>
                </Grid>
              </VerticalGrid>
              <VerticalGrid
                type="item"
                children={show && <BioRepeelTop />}
                sx={{ color: "biorepeel.main" }}
              />
            </VerticalGrid>
          </VerticalGrid>
          <VerticalGrid type="item" children={<BioRepeelBottom />} />
        </VerticalGrid>
      </Box>
      <Box
        position={"fixed"}
        top={0}
        left={0}
        width={"100%"}
        zIndex={ZIndexes.background}
      >
        <img src={sfondoGamma} width={"100%"} height={"auto"} />
      </Box>
    </>
  );
}

function BioRepeelTop() {
  const [opened, setOpened] = useState(false);
  const [biorepeel_prodotto, biorepeel_pack1, biorepeel_pack2] =
    useAsset<ImageKeys>([
      "biorepeel_prodotti",
      "biorepeel_pack1",
      "biorepeel_pack2",
    ]);
  const ref = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<DOMRect | undefined>();

  useEffect(() => {
    if (ref.current === null) return;
    setRect(ref.current.getBoundingClientRect());
  }, [opened]);

  return (
    <>
      <Grid
        container
        direction={"row"}
        height={"100%"}
        justifyContent={"center"}
        alignContent={"flex-start"}
      >
        {opened && (
          <Grid item xs={2} height={"100%"} sx={focusInAnimation}>
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignContent={"center"}
              height={"100%"}
            >
              <Grid item>
                <img src={biorepeel_pack1} style={{ width: "100%" }}></img>
              </Grid>
              <Grid item>
                <Typography textAlign={"center"}>
                  Formato da 5 fiale da 6 ml
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={5} ref={ref} height={"100%"} />
        {opened && (
          <Grid item xs={2} height={"100%"} sx={focusInAnimation}>
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignContent={"center"}
              height={"100%"}
            >
              <Grid item>
                <img
                  src={biorepeel_pack2}
                  style={{ width: "100%", height: "auto" }}
                ></img>
              </Grid>
              <Grid item>
                <Typography textAlign={"center"}>
                  Formato da 3 fiale da 12 ml
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {!opened && (
          <Grid item xs={6}>
            <Center>
              <BiorepeelTessere width={"50rem"} />
            </Center>
          </Grid>
        )}
        {rect && (
          <Grid
            container
            justifyContent={"flex-end"}
            alignContent={"center"}
            position={"fixed"}
            top={rect.top}
            left={rect.left}
            width={rect.width}
            height={rect.height}
            sx={{
              transition: !transitionEnabled ? undefined : "all 1s ease-in-out",
              ...focusInAnimation,
            }}
            onClick={() => setOpened(!opened)}
          >
            <img
              src={biorepeel_prodotto}
              style={{ width: "auto", height: "100%" }}
            ></img>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function BioRepeelBottom() {
  const [selected, setSelected] = useState<number>(0);
  return (
    <NewVerticalScrollableCard
      color="biorepeelDots"
      selected={selected}
      onSelectedIndexChange={setSelected}
      sx={{
        color: "black",
        backgroundColor: "bgGrey.light",
      }}
      elements={[
        {
          element: (
            <KeyMessages
              color="biorepeel"
              description={
                <Grid
                  container
                  direction={"row"}
                  gap={1}
                  justifyContent={"center"}
                >
                  <Grid item alignSelf={"center"}>
                    <Typography variant="body1">
                      Azione combinata: esfoliante, biostimolante,
                      rivitalizzante
                    </Typography>
                  </Grid>
                </Grid>
              }
              messages={[
                {
                  type: "line",
                  title: "Promuove il turnover cellulare",
                },
                {
                  type: "line",
                  title: "Svolge un'azione biostimolante profonda",
                },
                {
                  type: "line",
                  title: "Rigenera il film idrolipidico",
                },
                {
                  type: "line",
                  title: "Non è fotosensibilizzante",
                },
              ]}
            />
          ),
        },
        {
          element: <BioRepeelPage2 />,
        },
      ]}
    />
  );
}
function BioRepeelPage2() {
  const [
    biorepeel_trattamenti,
    biorepeel_come_si_applica0,
    biorepeel_come_si_applica1,
    biorepeel_composizione,
    biorepeel_scopri_di_piu,
    biorepeel_trattamenti1,
    biorepeel_trattamenti2,
    biorepeel_info,
  ] = useAsset<VideoKeys>([
    "biorepeel_addome",
    "biorepeel_come_si_applica0",
    "biorepeel_come_si_applica1",
    "biorepeel_composizione",
    "biorepeel_scopri_di_piu",
    "biorepeel_trattamenti1",
    "biorepeel_trattamenti2",
    "biorepeel_info",
  ]);
  const [biorepeel_BA_1_before, biorepeel_BA_1_after] = useAsset<ImageKeys>([
    "biorepeel_BA_1_before",
    "biorepeel_BA_1_after",
  ]);

  const { height } = useWindowSize();
  const cardHeight = useMemo(
    () => (height != null ? height * 0.28 : null),
    [height]
  );
  const dispatch = useAppDispatch();

  return (
    <Grid
      container
      justifyContent={"center"}
      height={"100%"}
      pl={7}
      direction={"row"}
      alignContent={"center"}
      sx={{ backgroundColor: "white" }}
    >
      {cardHeight && (
        <HorizontalGrid
          type="container"
          gap={1}
          firstColumn={`${heightToWidth(cardHeight) * 0.5}px`}
          columnSize={heightToWidth(cardHeight) + 11}
          columns={4}
          sx={{ height: cardHeight + 48 }}
        >
          <HorizontalGrid type="item">
            <TextCard title="Before & After" color="biorepeel">
              <GenericCard
                byHeight
                height={cardHeight}
                color="biorepeel"
                mediaChild={(rect) => (
                  <Grid
                    container
                    justifyContent={"center"}
                    style={{ width: "100%", height: rect.height }}
                  >
                    <Grid item style={{ width: `${rect.height * 0.6}px` }}>
                      <BeforeAndAfter
                        rect={rect}
                        before={biorepeel_BA_1_before}
                        after={biorepeel_BA_1_after}
                      />
                    </Grid>
                  </Grid>
                )}
                onClick={(rect) => {
                  if (rect === undefined) return;
                  dispatch(
                    openModal({
                      modalData: {
                        type: "beforeAndAfter",
                        images: [
                          "biorepeel_BA_1",
                          "biorepeel_BA_2",
                          "biorepeel_BA_3",
                        ],
                      },
                      rect,
                    })
                  );
                }}
              >
                <Line color="biorepeel" height={6} />
              </GenericCard>
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid type="item">
            <TextCard title="Composizione" color="biorepeel">
              <GenericCard
                byHeight
                video={biorepeel_composizione}
                color="biorepeel"
                startTime={5}
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid type="item">
            <TextCard title="Come si applica" color="biorepeel">
              <GenericCard
                byHeight
                video={biorepeel_come_si_applica0}
                color="biorepeel"
                startTime={6}
                onClick={(rect, id) => {
                  rect &&
                    dispatch(
                      openModal({
                        modalData: {
                          type: "carousel",
                          items: [
                            {
                              type: "video",
                              src: biorepeel_come_si_applica0,
                              style: { height: "100%", width: "auto" },
                            },
                            {
                              type: "video",
                              src: biorepeel_come_si_applica1,
                              style: { height: "100%", width: "auto" },
                            },
                          ],
                        },
                        rect,
                        animated: true,
                        id,
                      })
                    );
                }}
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid type="item">
            <TextCard title="Trattamenti" color="biorepeel">
              <GenericCard
                byHeight
                video={biorepeel_trattamenti}
                color="biorepeel"
                onClick={(rect, id) => {
                  rect &&
                    dispatch(
                      openModal({
                        modalData: {
                          type: "carousel",
                          items: [
                            {
                              type: "video",
                              src: biorepeel_trattamenti,
                              style: { height: "90%" },
                            },
                            { type: "video", src: biorepeel_trattamenti1 },
                            { type: "video", src: biorepeel_trattamenti2 },
                          ],
                        },
                        rect,
                        animated: true,
                        id,
                      })
                    );
                }}
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid type="item">
            <TextCard title="Scopri di più" color="biorepeel">
              <GenericCard
                byHeight
                video={biorepeel_scopri_di_piu}
                color="biorepeel"
                startTime={3}
                onClick={(rect, id) => {
                  rect &&
                    dispatch(
                      openModal({
                        modalData: {
                          type: "carousel",
                          items: [
                            {
                              type: "video",
                              src: biorepeel_scopri_di_piu,
                            },
                            {
                              type: "video",
                              src: biorepeel_info,
                            },
                          ],
                        },
                        rect,
                        animated: true,
                        id,
                      })
                    );
                }}
              />
            </TextCard>
          </HorizontalGrid>
        </HorizontalGrid>
      )}
    </Grid>
  );
}
