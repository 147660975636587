import { useMemo, useState } from "react";
import {
  InternalPage,
  ZIndexes,
} from "../../components/InternalPage/InternalPage";
import { useRouter } from "../../components/MainRouter/MainRouter";
import { Routes } from "../../utils/routes";
import { Box, Typography } from "@mui/material";
import { HistoryPage } from "./history.page";
import { useAsset } from "../../components/AsssetsLoader";
import { ImageKeys } from "../../utils/assets";
import { animated, config } from "react-spring";
import { Header } from "../prodotti/BioRepeelPage";
import { UltherapyLogo } from "../../components/Logos/UltherapyLogo";
import { VideoKeys } from "../../utils/assets";
import { RadiesseLogo } from "../../components/Logos/RadiesseLogo";
import { BeloteroLogo } from "../../components/Logos/BeloteroLogo";
import { minTimeVideoDuration } from "../../constants";
import { useAppDispatch } from "../../redux/hooks";
import { setYear } from "../../redux/history.reducer";
import { BGVideo } from "../prodotti";

export function DnaPage() {
  const { currentRoute, args, changeRoute } = useRouter<DOMRect>();

  const isActive = useMemo(() => currentRoute === Routes.dna, [currentRoute]);
  const dispatch = useAppDispatch();

  if (isActive) {
    return (
      <InternalPage
        color="smalt"
        backgroundColor="black"
        rect={args}
        goUpper={() => {
          changeRoute(Routes.home, { showPrevious: false });
          dispatch(setYear(null));
        }}
        subsection={"Chi siamo"}
        goNext={() => {
          changeRoute(Routes.inostriprodotti, { showPrevious: false });
          dispatch(setYear(null));
        }}
        sectionHeader={
          <Header color="smalt" showEverywhere>
            <Typography variant="h5" textAlign="center">
              IL <strong>DNA</strong> DELL'<i>innovazione</i>
            </Typography>
            <Typography
              variant="body1"
              style={{
                opacity: 0,
                animation: "blur 3s ease-out",
                animationDelay: "1s",
                animationFillMode: "forwards",
                textAlign: "center",
              }}
            >
              Seleziona la data e scopri di più
            </Typography>
          </Header>
        }
      >
        <History />
        <BGVideo video="chi_siamo_sfondo" />
      </InternalPage>
    );
  } else {
    return <></>;
  }
}

interface PrevVideoProps {
  video: VideoKeys;
  video_sfondo: VideoKeys;
  children: JSX.Element;
}
export function PrevVideo(props: PrevVideoProps) {
  const [intro, sfondo] = useAsset<VideoKeys>([
    props.video,
    props.video_sfondo,
  ]);

  const [finished, setFinished] = useState(false);

  const date = useMemo(() => new Date() as any, []);

  return (
    <>
      {!finished && (
        <animated.div
          style={{
            backgroundColor: "black",
            position: "fixed",
            top: 0,
            zIndex: ZIndexes.everywhere + 100,
            height: "100%",
            width: "100%",
          }}
        >
          <video
            src={intro}
            height={"100%"}
            autoPlay
            style={{ maxWidth: "100%" }}
            onClick={() => {
              const diff = (new Date() as any) - date;
              if (diff > minTimeVideoDuration) {
                setFinished(true);
              }
            }}
            onEnded={() => {
              setFinished(true);
            }}
          />
        </animated.div>
      )}
      <Box
        width={"100%"}
        height={"100%"}
        position={"fixed"}
        bottom={0}
        zIndex={ZIndexes.background}
      >
        <video
          src={sfondo}
          width={"100%"}
          height={"auto"}
          autoPlay
          muted
          loop
          style={{ position: "absolute", bottom: 0 }}
        />
      </Box>
      <>{props.children}</>
    </>
  );
}

function History() {
  const duration = 20 * 1000;

  const [
    dna_1908,
    dna_1911_1,
    dna_1911_2,
    dna_1953_1,
    dna_1953_2,
    dna_1964,
    dna_1968,
    dna_1968_3,
    dna_2008,
    dna_2020s,
  ] = useAsset<ImageKeys>([
    "dna_1908",
    "dna_1911_1",
    "dna_1911_2",
    "dna_1953_1",
    "dna_1953_2",
    "dna_1964",
    "dna_1968",
    "dna_1968_3",
    "dna_2008",
    "dna_2020",
  ]);
  return (
    <HistoryPage
      years={{
        1908: {
          elements: [
            {
              type: "img",
              src: dna_1908,
              top: "36%",
              leftOffset: 50,
              path: "M 0 0 Q 25 11 30 0",
              config: {
                duration,
              },
            },
          ],
        },
        1911: {
          elements: [
            {
              type: "img",
              src: dna_1911_1,
              top: "30%",
              path: "M 0 0 L 0 17 L 0 -11 L 0 0",
              config: {
                duration,
              },
            },
            {
              type: "img",
              src: dna_1911_2,
              top: "72%",
              leftOffset: 150,
              maxWidth: "40%",
            },
            {
              type: "img",
              top: "70%",
              maxWidth: "50%",
              src: dna_1968_3,
              leftOffset: -20,
              path: "M 0 0 Q -2 -18 -13 -20 Q 15 -20 19 1 Q 15 12 -6 10 L 0 0",
              config: {
                duration,
                ...config.molasses,
              },
            },
          ],
        },
        1953: {
          elements: [
            {
              type: "img",
              src: dna_1953_2,
              top: "50%",
              leftOffset: 50,
              config: {
                duration,
                ...config.molasses,
              },
            },
            {
              type: "img",
              src: dna_1953_1,
              top: "50%",
              maxWidth: "3rem",
              leftOffset: 130,
              path: "M 0 0 Q 0 -13 -5 -16 T -5 11 L 0 0",
              config: {
                duration,
              },
            },
          ],
        },
        1964: {
          elements: [
            {
              type: "img",
              top: "53%",
              leftOffset: 50,
              src: dna_1964,
              config: {
                duration,
                ...config.molasses,
              },
            },
          ],
        },
        1968: {
          elements: [
            {
              type: "img",
              src: dna_1968,
              top: "45%",
              leftOffset: 50,
              path: "M 0 0 Q 25 11 30 0",
              config: {
                duration,
              },
            },
          ],
        },
        2008: {
          elements: [
            {
              type: "img",
              src: dna_2008,
              top: "50%",
              path: "M 0 0 Q 25 11 30 0",
              leftOffset: 50,
              config: {
                duration,
              },
            },
          ],
        },
        2010: {
          elements: [
            {
              type: "icon",
              icon: <RadiesseLogo height={"3rem"} fill="white" />,
              top: "40%",
              path: "M 0 0 Q 25 11 30 0",
              config: {
                duration,
              },
            },
          ],
        },
        2013: {
          elements: [
            {
              type: "icon",
              icon: <BeloteroLogo height={"3em"} />,
              top: "50%",
              path: "M 0 0 Q 25 11 30 0",
              config: {
                duration,
              },
            },
          ],
        },
        2014: {
          elements: [
            {
              type: "icon",
              icon: <UltherapyLogo height={"4rem"} fill="white" />,
              top: "50%",
              leftOffset: -150,
              path: "M 0 0 Q 25 11 30 0",
              config: {
                duration,
              },
            },
          ],
        },
        2020: {
          elements: [
            {
              type: "img",
              src: dna_2020s,
              top: "50%",
              path: "M 0 0 Q 25 11 30 0",
              config: {
                duration,
              },
            },
          ],
        },
      }}
    />
  );
}
