import { AssetEntry } from "../components/AsssetsLoader";
import { Quality } from "./assets";

enum AssetPriority {
  urgent = 3,
  high = 2,
  medium = 1,
  low = 0,
}

export const images: (baseUrl: string) => Record<string, AssetEntry> = (
  baseUrl: string
) => ({
  chi_siamo: {
    path: `${baseUrl}/images/home/chi_siamo.png`,
    priority: AssetPriority.urgent,
  },
  elite: {
    path: `${baseUrl}/images/home/elite.png`,
    priority: AssetPriority.urgent,
  },
  i_nostri_prodotti: {
    path: `${baseUrl}/images/home/i_nostri_prodotti.png`,
    priority: AssetPriority.urgent,
  },
  servizi: {
    path: `${baseUrl}/images/home/servizi.png`,
    priority: AssetPriority.urgent,
  },
  viso1: {
    path: `${baseUrl}/images/rotazioni/viso1.png`,
    priority: AssetPriority.high,
  },
  viso2: {
    path: `${baseUrl}/images/rotazioni/viso2.png`,
    priority: AssetPriority.high,
  },
  viso3: {
    path: `${baseUrl}/images/rotazioni/viso3.png`,
    priority: AssetPriority.high,
  },
  viso4: {
    path: `${baseUrl}/images/rotazioni/viso4.png`,
    priority: AssetPriority.high,
  },
  viso5: {
    path: `${baseUrl}/images/rotazioni/viso5.png`,
    priority: AssetPriority.high,
  },
  viso6: {
    path: `${baseUrl}/images/rotazioni/viso6.png`,
    priority: AssetPriority.high,
  },
  viso7: {
    path: `${baseUrl}/images/rotazioni/viso7.png`,
    priority: AssetPriority.high,
  },
  viso8: {
    path: `${baseUrl}/images/rotazioni/viso8.png`,
    priority: AssetPriority.high,
  },
  viso9: {
    path: `${baseUrl}/images/rotazioni/viso9.png`,
    priority: AssetPriority.high,
  },
  viso10: {
    path: `${baseUrl}/images/rotazioni/viso10.png`,
    priority: AssetPriority.high,
  },
  viso11: {
    path: `${baseUrl}/images/rotazioni/viso11.png`,
    priority: AssetPriority.high,
  },
  viso12: {
    path: `${baseUrl}/images/rotazioni/viso12.png`,
    priority: AssetPriority.high,
  },
  viso13: {
    path: `${baseUrl}/images/rotazioni/viso13.png`,
    priority: AssetPriority.high,
  },
  viso14: {
    path: `${baseUrl}/images/rotazioni/viso14.png`,
    priority: AssetPriority.high,
  },
  viso15: {
    path: `${baseUrl}/images/rotazioni/viso15.png`,
    priority: AssetPriority.high,
  },
  viso16: {
    path: `${baseUrl}/images/rotazioni/viso16.png`,
    priority: AssetPriority.high,
  },
  viso17: {
    path: `${baseUrl}/images/rotazioni/viso17.png`,
    priority: AssetPriority.high,
  },
  viso18: {
    path: `${baseUrl}/images/rotazioni/viso18.png`,
    priority: AssetPriority.high,
  },
  viso19: {
    path: `${baseUrl}/images/rotazioni/viso19.png`,
    priority: AssetPriority.high,
  },
  viso20: {
    path: `${baseUrl}/images/rotazioni/viso20.png`,
    priority: AssetPriority.high,
  },
  viso21: {
    path: `${baseUrl}/images/rotazioni/viso21.png`,
    priority: AssetPriority.high,
  },
  viso22: {
    path: `${baseUrl}/images/rotazioni/viso22.png`,
    priority: AssetPriority.high,
  },
  viso23: {
    path: `${baseUrl}/images/rotazioni/viso23.png`,
    priority: AssetPriority.high,
  },
  viso24: {
    path: `${baseUrl}/images/rotazioni/viso24.png`,
    priority: AssetPriority.high,
  },
  viso25: {
    path: `${baseUrl}/images/rotazioni/viso25.png`,
    priority: AssetPriority.high,
  },
  viso26: {
    path: `${baseUrl}/images/rotazioni/viso26.png`,
    priority: AssetPriority.high,
  },
  viso27: {
    path: `${baseUrl}/images/rotazioni/viso27.png`,
    priority: AssetPriority.high,
  },
  viso28: {
    path: `${baseUrl}/images/rotazioni/viso28.png`,
    priority: AssetPriority.high,
  },
  viso29: {
    path: `${baseUrl}/images/rotazioni/viso29.png`,
    priority: AssetPriority.high,
  },
  viso30: {
    path: `${baseUrl}/images/rotazioni/viso30.png`,
    priority: AssetPriority.high,
  },
  viso31: {
    path: `${baseUrl}/images/rotazioni/viso31.png`,
    priority: AssetPriority.high,
  },
  viso32: {
    path: `${baseUrl}/images/rotazioni/viso32.png`,
    priority: AssetPriority.high,
  },
  viso33: {
    path: `${baseUrl}/images/rotazioni/viso33.png`,
    priority: AssetPriority.high,
  },
  viso34: {
    path: `${baseUrl}/images/rotazioni/viso34.png`,
    priority: AssetPriority.high,
  },
  viso35: {
    path: `${baseUrl}/images/rotazioni/viso35.png`,
    priority: AssetPriority.high,
  },
  viso36: {
    path: `${baseUrl}/images/rotazioni/viso36.png`,
    priority: AssetPriority.high,
  },
  viso37: {
    path: `${baseUrl}/images/rotazioni/viso37.png`,
    priority: AssetPriority.high,
  },
  viso38: {
    path: `${baseUrl}/images/rotazioni/viso38.png`,
    priority: AssetPriority.high,
  },
  viso39: {
    path: `${baseUrl}/images/rotazioni/viso39.png`,
    priority: AssetPriority.high,
  },
  viso40: {
    path: `${baseUrl}/images/rotazioni/viso40.png`,
    priority: AssetPriority.high,
  },
  viso41: {
    path: `${baseUrl}/images/rotazioni/viso41.png`,
    priority: AssetPriority.high,
  },
  immagini_viso_belo1: {
    path: `${baseUrl}/images/immagini_viso/Belotero_angolo_mandibolare_ridefinizione.jpg`,
  },
  immagini_viso_belo2: {
    path: `${baseUrl}/images/immagini_viso/Belotero_filtro_riempimento.jpg`,
  },
  immagini_viso_belo3: {
    path: `${baseUrl}/images/immagini_viso/Belotero_guance_biorivitalizzazione.jpg`,
  },
  immagini_viso_belo4: {
    path: `${baseUrl}/images/immagini_viso/Belotero_jawline_ridefinizione.jpg`,
  },
  immagini_viso_belo5: {
    path: `${baseUrl}/images/immagini_viso/Belotero_labbra_correzione.jpg`,
  },
  immagini_viso_belo6: {
    path: `${baseUrl}/images/immagini_viso/Belotero_labbra_proiezione.jpg`,
  },
  immagini_viso_belo7: {
    path: `${baseUrl}/images/immagini_viso/Belotero_labbra_ridefinizione.jpg`,
  },
  immagini_viso_belo8: {
    path: `${baseUrl}/images/immagini_viso/Belotero_labbra_rimodellamento.jpg`,
  },
  immagini_viso_belo9: {
    path: `${baseUrl}/images/immagini_viso/Belotero_mento_proiezione.jpg`,
  },
  immagini_viso_belo10: {
    path: `${baseUrl}/images/immagini_viso/Belotero_pre-jowl_riempimento.jpg`,
  },
  immagini_viso_belo11: {
    path: `${baseUrl}/images/immagini_viso/Belotero_ruga_mentolabiale_riempimento.jpg`,
  },
  immagini_viso_belo12: {
    path: `${baseUrl}/images/immagini_viso/Belotero_rughe_della_marionetta_riempimento.jpg`,
  },
  immagini_viso_belo13: {
    path: `${baseUrl}/images/immagini_viso/Belotero_rughe_nasolabiali_lievi_rimepimento.jpg`,
  },
  immagini_viso_belo14: {
    path: `${baseUrl}/images/immagini_viso/Belotero_rughe_nasolabiali_profonde_rimepimento.jpg`,
  },
  immagini_viso_belo15: {
    path: `${baseUrl}/images/immagini_viso/Belotero_rughe_periorali_distensione.jpg`,
  },
  immagini_viso_belo16: {
    path: `${baseUrl}/images/immagini_viso/Belotero_rughe_periorbitali_distensione.jpg`,
  },
  immagini_viso_belo17: {
    path: `${baseUrl}/images/immagini_viso/Belotero_solco_lacrimale_riempimento.jpg`,
  },
  immagini_viso_belo18: {
    path: `${baseUrl}/images/immagini_viso/Belotero_tempie_anteriori_e_grave_svuotamento_riempimento.jpg`,
  },
  immagini_viso_belo19: {
    path: `${baseUrl}/images/immagini_viso/Belotero_tempie_anteriori_e_posteriori_riempimento.jpg`,
  },
  immagini_viso_belo20: {
    path: `${baseUrl}/images/immagini_viso/Belotero_zigomi_volumizzazione.jpg`,
  },
  immagini_viso_radi1: {
    path: `${baseUrl}/images/immagini_viso/Radiesse_angolo_mandibolare_ridefinzione.jpg`,
  },
  immagini_viso_radi2: {
    path: `${baseUrl}/images/immagini_viso/Radiesse_collo_biostimolazione.jpg`,
  },
  immagini_viso_radi3: {
    path: `${baseUrl}/images/immagini_viso/Radiesse_decollete_biostimolazione.jpg`,
  },
  immagini_viso_radi4: {
    path: `${baseUrl}/images/immagini_viso/Radiesse_guance_biostimolazione_1.jpg`,
  },
  immagini_viso_radi5: {
    path: `${baseUrl}/images/immagini_viso/Radiesse_guance_biostimolazione_2.jpg`,
  },
  immagini_viso_radi6: {
    path: `${baseUrl}/images/immagini_viso/Radiesse_guance_biostimolazione_3.jpg`,
  },
  immagini_viso_radi7: {
    path: `${baseUrl}/images/immagini_viso/Radiesse_jawline_ridefinizione.jpg`,
  },
  immagini_viso_radi8: {
    path: `${baseUrl}/images/immagini_viso/Radiesse_mani_biostimolazione_1.jpg`,
  },
  immagini_viso_radi9: {
    path: `${baseUrl}/images/immagini_viso/Radiesse_mani_biostimolazione_2.jpg`,
  },
  immagini_viso_radi10: {
    path: `${baseUrl}/images/immagini_viso/Radiesse_rughe_della_marionetta_riempimento.jpg`,
  },
  collo: { path: `${baseUrl}/images/aree/collo.png` },
  decolte: { path: `${baseUrl}/images/aree/decollete.png` },
  mano: { path: `${baseUrl}/images/aree/mano.png` },
  ti: { path: `${baseUrl}/images/aree/ti.png` },
  tm: { path: `${baseUrl}/images/aree/tm.png` },
  sottomento: { path: `${baseUrl}/images/aree/sottomento.png` },
  ts: { path: `${baseUrl}/images/aree/ts.png` },
  area_dx_collo: { path: `${baseUrl}/images/aree/laterale/latoDX/collo.png` },
  area_dx_decollete: {
    path: `${baseUrl}/images/aree/laterale/latoDX/decollete.png`,
  },
  area_dx_mano: { path: `${baseUrl}/images/aree/laterale/latoDX/mano.png` },
  area_dx_sottomento: {
    path: `${baseUrl}/images/aree/laterale/latoDX/sottomento.png`,
  },
  area_dx_ti: { path: `${baseUrl}/images/aree/laterale/latoDX/ti.png` },
  area_dx_tm: { path: `${baseUrl}/images/aree/laterale/latoDX/tm.png` },
  area_dx_ts: { path: `${baseUrl}/images/aree/laterale/latoDX/ts.png` },
  area_sx_collo: { path: `${baseUrl}/images/aree/laterale/latoSX/collo.png` },
  area_sx_decollete: {
    path: `${baseUrl}/images/aree/laterale/latoSX/decollete.png`,
  },
  area_sx_mano: { path: `${baseUrl}/images/aree/laterale/latoSX/mano.png` },
  area_sx_sottomento: {
    path: `${baseUrl}/images/aree/laterale/latoSX/sottomento.png`,
  },
  area_sx_ti: { path: `${baseUrl}/images/aree/laterale/latoSX/ti.png` },
  area_sx_tm: { path: `${baseUrl}/images/aree/laterale/latoSX/tm.png` },
  area_sx_ts: { path: `${baseUrl}/images/aree/laterale/latoSX/ts.png` },
  collo_punto: { path: `${baseUrl}/images/punti/Punti-viso_0002_Collo.png` },
  sottomento_punto: {
    path: `${baseUrl}/images/punti/Punti-viso_0001_Sottomento.png`,
  },
  decollete_punto: {
    path: `${baseUrl}/images/punti/Punti-viso_0000_Decollete.png`,
  },
  mani_punto: { path: `${baseUrl}/images/punti/Punti-viso_0003_mani.png` },
  ts_periorbitali: {
    path: `${baseUrl}/images/punti/Punti-viso_0000s_0000_TS-periorbitali.png`,
  },
  ts_tempie: {
    path: `${baseUrl}/images/punti/Punti-viso_0000s_0001_TS-tempie.png`,
  },
  ts_rughe_glablellari: {
    path: `${baseUrl}/images/punti/Punti-viso_0000s_0002_TS-rughe-glabellari.png`,
  },
  ts_rughe_frontali: {
    path: `${baseUrl}/images/punti/Punti-viso_0000s_0003_TS-rughe-frontali.png`,
  },
  ti_rughe_marionetta: {
    path: `${baseUrl}/images/punti/Punti-viso_0002s_0000_TI-rughe-marionetta.png`,
  },
  ti_ruga_mentolabiale: {
    path: `${baseUrl}/images/punti/Punti-viso_0002s_0001_TI-ruga-mentolabiale.png`,
  },
  ti_pre_jowl: {
    path: `${baseUrl}/images/punti/Punti-viso_0002s_0002_TI-pre-jowl.png`,
  },
  ti_labbra: {
    path: `${baseUrl}/images/punti/Punti-viso_0002s_0003_TI-labbra.png`,
  },
  ti_angolo_mandibolare: {
    path: `${baseUrl}/images/punti/Punti-viso_0002s_0004_TI-angolo-mandibolare.png`,
  },
  ti_jawline: {
    path: `${baseUrl}/images/punti/Punti-viso_0002s_0005_TI-jawline.png`,
  },
  ti_mento: { path: `${baseUrl}/images/punti/ti_mento.png` },
  tm_guance: {
    path: `${baseUrl}/images/punti/Punti-viso_0001s_0000_TM-guance.png`,
  },
  tm_zigomi: {
    path: `${baseUrl}/images/punti/Punti-viso_0001s_0001_TM-zigomi.png`,
  },
  tm_nasolabiali: {
    path: `${baseUrl}/images/punti/Punti-viso_0001s_0002_TM-nasolabiali.png`,
  },
  tm_filtro: {
    path: `${baseUrl}/images/punti/Punti-viso_0001s_0003_TM-filtro.png`,
  },
  tm_rughe_periorali: {
    path: `${baseUrl}/images/punti/Punti-viso_0001s_0004_TM-rughe-periorali.png`,
  },
  tm_solco_lacrimale: {
    path: `${baseUrl}/images/punti/Punti-viso_0001s_0005_TM-solco-lacrimale.png`,
  },
  ti_punto: { path: `${baseUrl}/images/punti/Punti-viso_ti.png` },
  tm_punto: { path: `${baseUrl}/images/punti/Punti-viso_tm.png` },
  ts_punto: { path: `${baseUrl}/images/punti/Punti-viso_ts.png` },
  viso_punto: { path: `${baseUrl}/images/punti/Punti-viso_VISO.png` },
  ts_periorbitali_lato: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0000s_0000_TS-periorbitali.png`,
  },
  ts_tempie_lato: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0000s_0001_TS-tempie.png`,
  },
  tm_guance_lato: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0001s_0000_TM-guance.png`,
  },
  tm_zigomi_lato: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0001s_0001_TM-zigomi.png`,
  },
  tm_nasolabiali_lato: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0001s_0002_TM-nasolabiali.png`,
  },
  tm_solco_lacrimale_lato: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0001s_0005_TM-solco-lacrimale.png`,
  },
  ti_rughe_marionetta_lato: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0002s_0000_TI-rughe-marionetta.png`,
  },
  ti_pre_jowl_lato: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0002s_0002_TI-pre-jowl.png`,
  },
  ti_angolo_mandibolare_lato: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0002s_0004_TI-angolo-mandibolare.png`,
  },
  ti_jawline_lato: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0002s_0005_TI-jawline.png`,
  },
  decollete_dx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0004_Decollete_dx.png`,
  },
  decollete_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0004_Decollete.png`,
  },
  mani_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0007_mani.png`,
  },
  collo_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0006_Collo.png`,
  },
  collo_dx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0006_Collo_dx.png`,
  },
  sottomento_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0005_Sottomento.png`,
  },
  sottomento_dx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0005_Sottomento_dx.png`,
  },
  ti_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0000_viso-copia.png`,
  },
  ti_dx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0000_viso-copia_dx.png`,
  },
  tm_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0001_viso-copia-2.png`,
  },
  tm_dx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0001_viso-copia-2_dx.png`,
  },
  ts_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0002_viso-copia-3.png`,
  },
  ts_dx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0002_viso-copia-3_dx.png`,
  },
  viso_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0003_viso.png`,
  },
  viso_dx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0003_viso_dx.png`,
  },
  mento_sx: { path: `${baseUrl}/images/punti/Punti_Laterali/mento.png` },
  mento_dx: { path: `${baseUrl}/images/punti/Punti_Laterali/mento_dx.png` },
  tempie_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0000s_0001_TS-tempie_sx.png`,
  },
  periorbital_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0000s_0000_TS-periorbitali_sx.png`,
  },
  nasolabiali_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0001s_0002_TM-nasolabiali_sx.png`,
  },
  guance_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0001s_0000_TM-guance_sx.png`,
  },
  zigomi_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0001s_0001_TM-zigomi_sx.png`,
  },
  solco_lacrimale_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0001s_0005_TM-solco-lacrimale_sx.png`,
  },
  marionetta_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0002s_0000_TI-rughe-marionetta_sx.png`,
  },
  prejowl_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0002s_0002_TI-pre-jowl_sx.png`,
  },
  jawline_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0002s_0005_TI-jawline_sx.png`,
  },
  angolo_mandibolare_sx: {
    path: `${baseUrl}/images/punti/Punti_Laterali/Punti-viso-laterale_0002s_0004_TI-angolo-mandibolare_sx.png`,
  },
  bocoture_bg: {
    path: `${baseUrl}/images/bocoture/bg.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  bocoture_ricostruzione0: {
    path: `${baseUrl}/images/bocoture/ricostruzione.png`,
  },
  bocoture_risultati0: { path: `${baseUrl}/images/bocoture/risultati.png` },
  bocoture_pack_100: { path: `${baseUrl}/images/bocoture/pack_100.png` },
  bocoture_pack_50: { path: `${baseUrl}/images/bocoture/pack_50.png` },
  bocoture_science_brief0: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-0.png`,
  },
  bocoture_science_brief1: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-1.png`,
  },
  bocoture_science_brief2: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-2.png`,
  },
  bocoture_science_brief3: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-3.png`,
  },
  bocoture_science_brief4: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-4.png`,
  },
  bocoture_science_brief5: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-5.png`,
  },
  bocoture_science_brief6: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-6.png`,
  },
  bocoture_science_brief7: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-7.png`,
  },
  bocoture_science_brief8: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-8.png`,
  },
  bocoture_science_brief9: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-9.png`,
  },
  bocoture_science_brief10: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-10.png`,
  },
  bocoture_science_brief11: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-11.png`,
  },
  bocoture_science_brief12: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-12.png`,
  },
  bocoture_science_brief13: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-13.png`,
  },
  bocoture_science_brief14: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-14.png`,
  },
  bocoture_science_brief15: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-15.png`,
  },
  bocoture_science_brief16: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-16.png`,
  },
  bocoture_science_brief17: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-17.png`,
  },
  bocoture_science_brief18: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-18.png`,
  },
  bocoture_science_brief19: {
    path: `${baseUrl}/pdf/bocoture_science_brief/bocoture_science_brief-19.png`,
  },
  radiesse_composizione0: {
    path: `${baseUrl}/images/radiesse/composizione-0.png`,
  },
  radiesse_meccanismo_dazione0: {
    path: `${baseUrl}/images/radiesse/meccanismo_dazione-0.png`,
  },
  radiesse_profilo_di_sicurezza0: {
    path: `${baseUrl}/images/radiesse/profilo_di_sicurezza-0.png`,
  },
  radiesse_bollino: { path: `${baseUrl}/images/radiesse/bollini.png` },
  radiesse_sfondo: { path: `${baseUrl}/images/radiesse/radiesse_sfondo.png` },
  radiesse_intro: {
    path: `${baseUrl}/images/sfondo/SfondoRadiesse.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
    quality: [Quality.low],
  },
  radiesse_prodotto: {
    path: `${baseUrl}/images/radiesse/Radiesse_Package-Angled_scontorno.png`,
  },
  radiesse_plus_prodotto: {
    path: `${baseUrl}/images/radiesse/Radiesse_Plus_Package-Angled_scontorno.png`,
  },
  radiesse_card: { path: `${baseUrl}/images/radiesse/card.png` },
  radiesse_tecnologia0: {
    path: `${baseUrl}/images/radiesse/tecnologia-0.png`,
  },
  radiesse_tecnologia1: {
    path: `${baseUrl}/images/radiesse/tecnologia-1.png`,
  },
  radiesse_christian_after: {
    path: `${baseUrl}/images/radiesse/BEFORE_AFTER/01-after.png`,
  },
  radiesse_christian_before: {
    path: `${baseUrl}/images/radiesse/BEFORE_AFTER/01-before.png`,
  },
  radiesse_egle_after: {
    path: `${baseUrl}/images/radiesse/BEFORE_AFTER/02-after.png`,
  },
  radiesse_egle_before: {
    path: `${baseUrl}/images/radiesse/BEFORE_AFTER/02-before.png`,
  },
  radiesse_ewa_after: {
    path: `${baseUrl}/images/radiesse/BEFORE_AFTER/03-after.png`,
  },
  radiesse_ewa_before: {
    path: `${baseUrl}/images/radiesse/BEFORE_AFTER/03-before.png`,
  },
  radiesse_irene_after: {
    path: `${baseUrl}/images/radiesse/BEFORE_AFTER/04-after.png`,
  },
  radiesse_irene_before: {
    path: `${baseUrl}/images/radiesse/BEFORE_AFTER/04-before.png`,
  },
  ultherapy_card: { path: `${baseUrl}/images/ultherapy/card.png` },
  ultherapy_banner_sup_img: {
    path: `${baseUrl}/images/ultherapy/banner_superiore.png`,
  },
  ultherapy_sfondo: { path: `${baseUrl}/images/ultherapy/sfondo.png` },
  ultherapy_intro: {
    path: `${baseUrl}/images/sfondo/SfondoUltherapy.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
    quality: [Quality.low],
  },
  ultherapy_award: {
    path: `${baseUrl}/images/ultherapy/2023_Award_Winner.png`,
  },
  ultherapy_device: {
    path: `${baseUrl}/images/ultherapy/Ultherapy-Device.png`,
  },
  ultherapy_a1_after: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/A/after01.png`,
  },
  ultherapy_a1_before: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/A/before01.png`,
  },
  ultherapy_a2_after: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/A/after02.png`,
  },
  ultherapy_a2_before: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/A/before02.png`,
  },
  ultherapy_b1_after: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/B/after01.png`,
  },
  ultherapy_b1_before: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/B/before01.png`,
  },
  ultherapy_b2_after: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/B/after02.png`,
  },
  ultherapy_b2_before: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/B/before02.png`,
  },
  ultherapy_c1_after: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/C/after01.png`,
  },
  ultherapy_c1_before: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/C/before01.png`,
  },
  ultherapy_c2_after: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/C/after02.png`,
  },
  ultherapy_c2_before: {
    path: `${baseUrl}/images/ultherapy/BEFORE_AFTER/C/before02.png`,
  },
  belotero_reologia: { path: `${baseUrl}/images/belotero/reologia.png` },
  belotero_lips: { path: `${baseUrl}/images/belotero/lips.png` },
  belotero_balance: { path: `${baseUrl}/images/belotero/balance.png` },
  belotero_sfondo: { path: `${baseUrl}/images/belotero/sfondo.png` },
  belotero_intro: {
    path: `${baseUrl}/images/sfondo/SfondoBelotero.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
    quality: [Quality.low],
  },
  belotero_intense: { path: `${baseUrl}/images/belotero/intense.png` },
  belotero_pack_balance: {
    path: `${baseUrl}/images/belotero/packs/Balance.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_pack_intensep: {
    path: `${baseUrl}/images/belotero/packs/Intense+.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_pack_balancep: {
    path: `${baseUrl}/images/belotero/packs/Balance+.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_pack_intense: {
    path: `${baseUrl}/images/belotero/packs/Intense.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_pack_lipsc: {
    path: `${baseUrl}/images/belotero/packs/LipsC.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_pack_lipss: {
    path: `${baseUrl}/images/belotero/packs/LipsS.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_pack_revive: {
    path: `${baseUrl}/images/belotero/packs/Revive.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_pack_softp: {
    path: `${baseUrl}/images/belotero/packs/Soft+.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_pack_soft: {
    path: `${baseUrl}/images/belotero/packs/Soft.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_pack_volumep: {
    path: `${baseUrl}/images/belotero/packs/Volume+.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_pack_volume: {
    path: `${baseUrl}/images/belotero/packs/Volume.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  belotero_prodotti: {
    path: `${baseUrl}/images/belotero/Prodotti_BELOTERO_STUDEO.png`,
  },
  belotero_scopridipiu_intense: {
    path: `${baseUrl}/images/belotero/belotero_scopridipiu_intense.jpg`,
  },
  belotero_scopridipiu_volume: {
    path: `${baseUrl}/images/belotero/belotero_scopridipiu_volume.jpg`,
  },
  belotero_scopridipiu_balance: {
    path: `${baseUrl}/images/belotero/belotero_scopridipiu_balance.jpg`,
  },
  belotero_scopridipiu_lips: {
    path: `${baseUrl}/images/belotero/belotero_scopridipiu_lips.jpg`,
  },
  belotero_scopridipiu_revive: {
    path: `${baseUrl}/images/belotero/belotero_scopridipiu_revive.jpg`,
  },
  belotero_scopridipiu_soft: {
    path: `${baseUrl}/images/belotero/belotero_scopridipiu_soft.jpg`,
  },
  belotero_comeagisce0: { path: `${baseUrl}/images/belotero/comeagisce.png` },
  belotero_scopridipiu0: {
    path: `${baseUrl}/images/belotero/scopridipiu-0.png`,
  },
  belotero_scopridipiu1: {
    path: `${baseUrl}/images/belotero/scopridipiu-1.png`,
  },
  belotero_science_brief0: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-0.png`,
  },
  belotero_science_brief1: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-1.png`,
  },
  belotero_science_brief2: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-2.png`,
  },
  belotero_science_brief3: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-3.png`,
  },
  belotero_science_brief4: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-4.png`,
  },
  belotero_science_brief5: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-5.png`,
  },
  belotero_science_brief6: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-6.png`,
  },
  belotero_science_brief7: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-7.png`,
  },
  belotero_science_brief8: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-8.png`,
  },
  belotero_science_brief9: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-9.png`,
  },
  belotero_science_brief10: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-10.png`,
  },
  belotero_science_brief11: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-11.png`,
  },
  belotero_science_brief12: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-12.png`,
  },
  belotero_science_brief13: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-13.png`,
  },
  belotero_science_brief14: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-14.png`,
  },
  belotero_science_brief15: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-15.png`,
  },
  belotero_science_brief16: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-16.png`,
  },
  belotero_science_brief17: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-17.png`,
  },
  belotero_science_brief18: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-18.png`,
  },
  belotero_science_brief19: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-19.png`,
  },
  belotero_science_brief20: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-20.png`,
  },
  belotero_science_brief21: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-21.png`,
  },
  belotero_science_brief22: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-22.png`,
  },
  belotero_science_brief23: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-23.png`,
  },
  belotero_science_brief24: {
    path: `${baseUrl}/pdf/belotero_science_brief/belotero_science_brief-24.png`,
  },
  biorepeel_sfondo: {
    path: `${baseUrl}/images/biorepeel/sfondo.jpg`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  biorepeel_sfondo_gamma: {
    path: `${baseUrl}/images/biorepeel/Sfondo_gamma.png`,
  },
  biorepeel_prodotti: {
    path: `${baseUrl}/images/biorepeel/BioRePeelCl3_PRODOTTI_scontornati.png`,
  },
  biorepeel_applicazione0: {
    path: `${baseUrl}/images/biorepeel/applicazione.png`,
  },
  biorepeel_composizione0: {
    path: `${baseUrl}/images/biorepeel/composizione.png`,
  },
  biorepeel_scopridipiu0: {
    path: `${baseUrl}/images/biorepeel/scopri_di_piu.png`,
  },
  biorepeel_biorepeel_body: {
    path: `${baseUrl}/images/biorepeel/prodotto_info_linea-BioRePeelCl3BODY.png`,
  },
  biorepeel_biorepeel_fnd: {
    path: `${baseUrl}/images/biorepeel/BioRePeelCl3_FND.png`,
  },
  biorepeel_BA_1_before: {
    path: `${baseUrl}/images/biorepeel/BEFORE_AFTER/BioRePeel_1_Before.png`,
  },
  biorepeel_BA_1_after: {
    path: `${baseUrl}/images/biorepeel/BEFORE_AFTER/BioRePeel_1_After.png`,
  },
  biorepeel_BA_2_before: {
    path: `${baseUrl}/images/biorepeel/BEFORE_AFTER/BioRePeel_2_Before.png`,
  },
  biorepeel_BA_2_after: {
    path: `${baseUrl}/images/biorepeel/BEFORE_AFTER/BioRePeel_2_After.png`,
  },
  biorepeel_BA_3_before: {
    path: `${baseUrl}/images/biorepeel/BEFORE_AFTER/BioRePeel_3_Before.png`,
  },
  biorepeel_BA_3_after: {
    path: `${baseUrl}/images/biorepeel/BEFORE_AFTER/BioRePeel_3_After.png`,
  },
  biorepeel_BA_4_before: {
    path: `${baseUrl}/images/biorepeel/BEFORE_AFTER/BioRePeel_4_Before.png`,
  },
  biorepeel_BA_4_after: {
    path: `${baseUrl}/images/biorepeel/BEFORE_AFTER/BioRePeel_4_After.png`,
  },
  biorepeel_pack1: { path: `${baseUrl}/images/biorepeel/Pack1.png` },
  biorepeel_pack2: { path: `${baseUrl}/images/biorepeel/Pack2.png` },
  dosaggio0: { path: `${baseUrl}/pdf/dosaggio/dosaggio.png` },
  rcp0: { path: `${baseUrl}/pdf/rcp/RCP-0.png` },
  rcp1: { path: `${baseUrl}/pdf/rcp/RCP-1.png` },
  rcp2: { path: `${baseUrl}/pdf/rcp/RCP-2.png` },
  rcp3: { path: `${baseUrl}/pdf/rcp/RCP-3.png` },
  logo_elite: { path: `${baseUrl}/images/elite/logo_elite.png` },
  logo_elite_nero: { path: `${baseUrl}/images/elite/logo_elite_nero.png` },
  elite_advanced_training_sfondo: {
    path: `${baseUrl}/images/elite/advanced_training/sfondo.png`,
  },
  elite_imclass_gallery_1: {
    path: `${baseUrl}/images/elite/advanced_training/imclass/2.jpg`,
  },
  elite_imclass_gallery_2: {
    path: `${baseUrl}/images/elite/advanced_training/imclass/3.jpg`,
  },
  elite_imexs_gallery_1: {
    path: `${baseUrl}/images/elite/advanced_training/imexs/Global_Mexs1.jpg`,
  },
  elite_imexs_gallery_2: {
    path: `${baseUrl}/images/elite/advanced_training/imexs/Global_Mexs2.jpg`,
  },
  elite_imexs_gallery_3: {
    path: `${baseUrl}/images/elite/advanced_training/imexs/Global_Mexs3.jpg`,
  },
  elite_itmexs_gallery_1: {
    path: `${baseUrl}/images/elite/advanced_training/itmexs/1_ATL9719.jpg`,
  },
  elite_itmexs_gallery_2: {
    path: `${baseUrl}/images/elite/advanced_training/itmexs/1_ATL9842.jpg`,
  },
  elite_itmexs_gallery_3: {
    path: `${baseUrl}/images/elite/advanced_training/itmexs/1_ATL9849.jpg`,
  },
  elite_handson_gallery_1: {
    path: `${baseUrl}/images/elite/advanced_training/handson/UMA.jpg`,
  },
  elite_expert_gallery_1: {
    path: `${baseUrl}/images/elite/advanced_training/expert/1.jpg`,
  },
  elite_expert_gallery_2: {
    path: `${baseUrl}/images/elite/advanced_training/expert/2.jpg`,
  },
  elite_expert_gallery_3: {
    path: `${baseUrl}/images/elite/advanced_training/expert/3.jpg`,
  },
  elite_expert_gallery_4: {
    path: `${baseUrl}/images/elite/advanced_training/expert/4.jpg`,
  },
  elite_amcw_gallery_1: {
    path: `${baseUrl}/images/elite/advanced_training/amcw/2023_merz_dinner_amwc-5858.jpg`,
  },
  elite_in_clinic_sfondo: {
    path: `${baseUrl}/images/elite/in_clinic/sfondo.png`,
  },
  elite_in_clinic_gallery_1: {
    path: `${baseUrl}/images/elite/in_clinic/AI_Immagine.png`,
  },
  elite_in_clinic_gallery_2: {
    path: `${baseUrl}/images/elite/in_clinic/AI_Immagine2.png`,
  },
  elite_in_clinic_gallery_3: {
    path: `${baseUrl}/images/elite/in_clinic/presentazione.jpg`,
  },
  elite_digital_marketing_sfondo: {
    path: `${baseUrl}/images/elite/digital_marketing/sfondo.png`,
  },
  elite_scientific_network_sfondo: {
    path: `${baseUrl}/images/elite/scientific_network/sfondo.png`,
  },
  elite_visibilita_digital: {
    path: `${baseUrl}/images/elite/digital_marketing/visibilita_digital.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  elite_consulenza_web1: {
    path: `${baseUrl}/images/elite/digital_marketing/web/Elite-BELOTERO-2023-0.png`,
  },
  elite_consulenza_web2: {
    path: `${baseUrl}/images/elite/digital_marketing/web/Elite-RADIESSE-2023-0.png`,
  },
  elite_consulenza_web3: {
    path: `${baseUrl}/images/elite/digital_marketing/web/Elite-ULTHERAPY-2023-0.png`,
  },
  elite_content_creation_1: {
    path: `${baseUrl}/images/elite/digital_marketing/content_creation/IMG_0139.jpeg`,
  },
  elite_content_creation_2: {
    path: `${baseUrl}/images/elite/digital_marketing/content_creation/IMG_0145.jpeg`,
  },
  elite_content_creation_3: {
    path: `${baseUrl}/images/elite/digital_marketing/content_creation/IMG_0288.jpeg`,
  },
  elite_content_creation_4: {
    path: `${baseUrl}/images/elite/digital_marketing/content_creation/IMG_0525.jpeg`,
  },
  elite_content_creation_5: {
    path: `${baseUrl}/images/elite/digital_marketing/content_creation/IMG_0532.jpeg`,
  },
  elite_content_creation_6: {
    path: `${baseUrl}/images/elite/digital_marketing/content_creation/IMG_1094.jpeg`,
  },
  elite_content_creation_7: {
    path: `${baseUrl}/images/elite/digital_marketing/content_creation/IMG_4092.jpeg`,
  },
  elite_content_creation_8: {
    path: `${baseUrl}/images/elite/digital_marketing/content_creation/IMG_8764.jpeg`,
  },
  elite_content_creation_9: {
    path: `${baseUrl}/images/elite/digital_marketing/content_creation/IMG_8823.jpeg`,
  },
  elite_content_creation_10: {
    path: `${baseUrl}/images/elite/digital_marketing/content_creation/IMG_9843.jpeg`,
  },
  elite_pr: {
    path: `${baseUrl}/images/elite/digital_marketing/pr.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  elite_belotero_0: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-0.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  elite_belotero_1: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-1.png`,
  },
  elite_belotero_2: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-2.png`,
  },
  elite_belotero_3: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-3.png`,
  },
  elite_belotero_4: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-4.png`,
  },
  elite_belotero_5: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-5.png`,
  },
  elite_belotero_6: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-6.png`,
  },
  elite_belotero_7: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-7.png`,
  },
  elite_belotero_8: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-8.png`,
  },
  elite_belotero_9: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-9.png`,
  },
  elite_belotero_10: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-10.png`,
  },
  elite_belotero_11: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-11.png`,
  },
  elite_belotero_12: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-12.png`,
  },
  elite_belotero_13: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-13.png`,
  },
  elite_belotero_14: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-14.png`,
  },
  elite_belotero_15: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-15.png`,
  },
  elite_belotero_16: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-16.png`,
  },
  elite_belotero_17: {
    path: `${baseUrl}/pdf/elite_belotero/Elite-BELOTERO-2023-17.png`,
  },
  elite_radiesse_0: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-0.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  elite_radiesse_1: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-1.png`,
  },
  elite_radiesse_2: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-2.png`,
  },
  elite_radiesse_3: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-3.png`,
  },
  elite_radiesse_4: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-4.png`,
  },
  elite_radiesse_5: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-5.png`,
  },
  elite_radiesse_6: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-6.png`,
  },
  elite_radiesse_7: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-7.png`,
  },
  elite_radiesse_8: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-8.png`,
  },
  elite_radiesse_9: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-9.png`,
  },
  elite_radiesse_10: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-10.png`,
  },
  elite_radiesse_11: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-11.png`,
  },
  elite_radiesse_12: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-12.png`,
  },
  elite_radiesse_13: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-13.png`,
  },
  elite_radiesse_14: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-14.png`,
  },
  elite_radiesse_15: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-15.png`,
  },
  elite_radiesse_16: {
    path: `${baseUrl}/pdf/elite_radiesse/Elite-RADIESSE-2023-16.png`,
  },
  elite_ultherapy_0: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-0.png`,
    priority: AssetPriority.high,
    type: "cacheBefore",
  },
  elite_ultherapy_1: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-1.png`,
  },
  elite_ultherapy_2: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-2.png`,
  },
  elite_ultherapy_3: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-3.png`,
  },
  elite_ultherapy_4: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-4.png`,
  },
  elite_ultherapy_5: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-5.png`,
  },
  elite_ultherapy_6: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-6.png`,
  },
  elite_ultherapy_7: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-7.png`,
  },
  elite_ultherapy_8: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-8.png`,
  },
  elite_ultherapy_9: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-9.png`,
  },
  elite_ultherapy_10: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-10.png`,
  },
  elite_ultherapy_11: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-11.png`,
  },
  elite_ultherapy_12: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-12.png`,
  },
  elite_ultherapy_13: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-13.png`,
  },
  elite_ultherapy_14: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-14.png`,
  },
  elite_ultherapy_15: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-15.png`,
  },
  elite_ultherapy_16: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-16.png`,
  },
  elite_ultherapy_17: {
    path: `${baseUrl}/pdf/elite_ultherapy/Elite-ULTHERAPY-2023-17.png`,
  },
  dna_1908: { path: `${baseUrl}/images/dna/1908_schweiz.png` },
  dna_1911_1: { path: `${baseUrl}/images/dna/1911.png` },
  dna_1911_2: { path: `${baseUrl}/images/dna/1911_3-8.png` },
  dna_1968_3: { path: `${baseUrl}/images/dna/history_1911_patentex1.png` },
  dna_1953_1: { path: `${baseUrl}/images/dna/1953_Merz_Frankfurt.png` },
  dna_1953_2: { path: `${baseUrl}/images/dna/1953.png` },
  dna_1964: {
    path: `${baseUrl}/images/dna/history_1964_schoenheit_von_innen1.png`,
  },
  dna_1968: { path: `${baseUrl}/images/dna/1968.png` },
  dna_2008: { path: `${baseUrl}/images/dna/2008_Xeomin_Bocouture-8b.png` },
  dna_2020: { path: `${baseUrl}/images/dna/2020.png` },
  servizi_marketing_thumbnail: {
    path: `${baseUrl}/images/servizi/marketing.png`,
  },
  servizi_formazione_thumbnail: {
    path: `${baseUrl}/images/servizi/formazione.png`,
  },
  servizi_app_thumbnail: { path: `${baseUrl}/images/servizi/MerzAPP.png` },
  servizi_medical_thumbnail: {
    path: `${baseUrl}/images/servizi/medical_affair.png`,
  },
  servizi_eventi_thumbnail: { path: `${baseUrl}/images/servizi/eventi.png` },
  apple_store_qr: { path: `${baseUrl}/images/servizi/apple_qr.png` },
  google_play_qr: { path: `${baseUrl}/images/servizi/google_qr.png` },
});

export type ImageKeys = string;
