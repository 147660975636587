import { Box, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ZIndexes } from "../InternalPage/InternalPage";
import {
  ModalDOMRect,
  ModalImageData,
  ModalPDFData,
  ModalBeforeAndAfterData,
  ModalVideoData,
  closeModal,
  triggerClose,
  Rect,
} from "../../redux/modal.reducer";
import { LeftIcon, RightIcon } from "../Icons/ArrowUP";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAsset } from "../AsssetsLoader";
import { BeforeAndAfter } from "../Card/BeforeAndAfter";
import { maxZoomScale, transitionEnabled } from "../../constants";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { ModalSlider } from "./modal.slider";
import { useFullScreenSize } from "../../utils/hooks";
import { ModalCarousel } from "./modal.carousel";
import { Center, VISIBILITA_ID } from "../../pages/elite/digital_marketing";

const duration = 1;

export function Modal() {
  const open = useAppSelector((state) => state.modal.open);
  const dispatch = useAppDispatch();

  return (
    <>
      <VisibilityClickableArea />
      <Box
        sx={{
          position: "fixed",
          zIndex: open ? ZIndexes.dialog : ZIndexes.hide,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: open ? 1 : 0,
          visibility: open ? "visible" : "hidden",
          transition: "opacity 1s ease-out",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
        onClick={() => dispatch(triggerClose())}
      >
        {open && (
          <Box
            width={"100%"}
            height={"100%"}
            sx={{ opacity: 1 }}
            children={<ModalContent />}
          />
        )}
      </Box>
    </>
  );
}

function ModalContent() {
  const { modalData, rect, id } = useAppSelector((state) => state.modal);
  if (rect === undefined) return <></>;
  switch (modalData?.type) {
    case "image":
      return <ModalImage {...modalData} rect={rect} />;
    case "video":
      return <ModalVideo {...modalData} rect={rect} />;
    case "pdf":
      return <ModalPDF {...modalData} rect={rect} />;
    case "beforeAndAfter":
      return <ModalBeforeAndAfter {...modalData} rect={rect} />;
    case "slider":
      return <ModalSlider {...modalData} rect={rect} />;
    case "carousel":
      return <ModalCarousel {...modalData} rect={rect} id={id} />;
    default:
      return <></>;
  }
}

function ModalImage(props: ModalImageData & { rect: ModalDOMRect }) {
  const style = useFullScreenSize(props.rect);
  const fullPageRef = useRef<HTMLImageElement>(null);

  const [rect, setRect] = useState<ModalDOMRect | undefined>();
  const { close, animated } = useAppSelector((state) => ({
    close: state.modal.close,
    animated: state.modal.animated,
  }));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fullPageRef.current) {
      if (animated) {
        setRect(props.rect);
        const timeout = setTimeout(
          () => setRect(fullPageRef.current!.getBoundingClientRect()),
          100
        );
        return () => clearTimeout(timeout);
      } else {
        setRect(fullPageRef.current!.getBoundingClientRect());
      }
    }
  }, [props.rect, animated]);

  useEffect(() => {
    if (close) {
      if (animated) {
        setRect(props.rect);
        const timeout = setTimeout(
          () => dispatch(closeModal()),
          duration * 1000 + 500
        );
        return () => clearTimeout(timeout);
      } else {
        dispatch(closeModal());
      }
    }
  }, [animated, close, dispatch, props.rect]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={"center"}
        width={"100%"}
        height="100%"
        direction="row"
      >
        <Grid item onClick={preventDefault}>
          <Grid
            container
            height={"100%"}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <Box>
                <Grid
                  container
                  height={"100%"}
                  direction="row"
                  justifyContent="center"
                  alignContent="center"
                  gap={1}
                >
                  <Grid item height={"100%"}>
                    <img
                      ref={fullPageRef}
                      src={props.img}
                      alt={`Thumbnail`}
                      style={{
                        ...style,
                        objectFit: "cover",
                        visibility: "hidden",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {rect && (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            transition: !transitionEnabled
              ? undefined
              : `width ${duration}s linear,height ${duration}s linear,top ${duration}s linear,left ${duration}s linear`,
            width: rect.width,
            height: rect.height,
            left: rect.left,
            top: rect.top,
            zIndex: ZIndexes.dialog,
            visibility: "visible",
          }}
        >
          <TransformWrapper maxScale={maxZoomScale}>
            <TransformComponent
              wrapperStyle={{
                overflow: "visible",
              }}
            >
              <img
                src={props.img}
                alt={`Thumbnail`}
                style={{
                  transition: !transitionEnabled
                    ? undefined
                    : `width ${duration}s linear,height ${duration}s linear,top ${duration}s linear,left ${duration}s linear`,
                  top: rect.top,
                  left: rect.left,
                  zIndex: ZIndexes.dialog,
                  visibility: "visible",
                  width: rect.width,
                  height: rect.height,
                  objectFit: "cover",
                }}
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      )}
    </>
  );
}

function ModalBeforeAndAfter(
  props: ModalBeforeAndAfterData & { rect: ModalDOMRect }
) {
  const images = useAsset(
    props.images.flatMap((e) => [`${e}_before`, `${e}_after`])
  );
  const [imageIndex, setImageIndex] = useState(0);
  const [before, after] = useMemo(
    () => [images[imageIndex], images[imageIndex + 1]],
    [images, imageIndex]
  );
  const fullPageRef = useRef<HTMLImageElement>(null);

  const [rect, setRect] = useState<ModalDOMRect | undefined>();
  const close = useAppSelector((state) => state.modal.close);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fullPageRef.current) {
      setRect(fullPageRef.current!.getBoundingClientRect());
      // setRect(props.rect);
      // const timeout = setTimeout(
      //   () => setRect(fullPageRef.current!.getBoundingClientRect()),
      //   100
      // );
      // return () => clearTimeout(timeout);
    }
  }, [props.rect]);

  useEffect(() => {
    if (close) {
      dispatch(closeModal());
      // setRect(props.rect);
      // const timeout = setTimeout(
      //   () => dispatch(closeModal()),
      //   duration * 1000 + 100
      // );
      // return () => clearTimeout(timeout);
    }
  }, [close, dispatch, props.rect]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={"center"}
        width={"100%"}
        height="100%"
        direction="row"
      >
        <Grid item onClick={preventDefault}>
          <Box
            minWidth={80}
            onClick={
              imageIndex === 0 ? undefined : () => setImageIndex((e) => e - 2)
            }
            visibility={imageIndex >= 1 ? "visible" : "hidden"}
          >
            <Grid
              direction="column"
              container
              alignContent="center"
              justifyContent="center"
              height={"100%"}
            >
              <LeftIcon width={"1rem"} />
            </Grid>
          </Box>
        </Grid>
        <Grid item onClick={preventDefault}>
          <Grid
            container
            height={"100%"}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <Box>
                <Grid
                  container
                  height={"100%"}
                  direction="row"
                  justifyContent="center"
                  alignContent="center"
                  gap={1}
                >
                  <Grid item height={"100%"}>
                    <img
                      ref={fullPageRef}
                      src={before}
                      alt={`Thumbnail`}
                      style={{
                        width: "auto",
                        height: "80vh",
                        objectFit: "cover",
                        visibility: "hidden",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item onClick={preventDefault}>
          <Box
            minWidth={80}
            onClick={
              imageIndex + 2 >= images.length
                ? undefined
                : () => setImageIndex((e) => e + 2)
            }
            visibility={imageIndex + 2 < images.length ? "visible" : "hidden"}
          >
            <Grid
              direction="column"
              container
              alignContent="center"
              justifyContent="center"
              height={"100%"}
            >
              <RightIcon width={"1rem"} />
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {rect && (
        <Box
          position={"fixed"}
          height={rect.height}
          width={rect.width}
          top={rect.top}
          left={rect.left}
          onClick={preventDefault}
        >
          <BeforeAndAfter before={before!} after={after!} rect={rect} enabled />
        </Box>
      )}
    </>
  );
}

function ModalVideo(props: ModalVideoData & { rect: ModalDOMRect }) {
  const style = useFullScreenSize(props.rect, props.style);
  const fullPageRef = useRef<HTMLVideoElement>(null);

  const [rect, setRect] = useState<ModalDOMRect | undefined>();
  const { close, animated, fullscreen, closeOnEnd } = useAppSelector(
    (state) => ({
      close: state.modal.close,
      animated: state.modal.animated,
      fullscreen: state.modal.fullscreen,
      closeOnEnd: state.modal.closeOnEnd,
    })
  );

  const videoRef = useRef<HTMLVideoElement>(null);

  const dispatch = useAppDispatch();

  const onEnded = useCallback(() => {
    if (closeOnEnd) {
      dispatch(triggerClose());
    }
  }, [closeOnEnd, dispatch]);

  useEffect(() => {
    if (fullPageRef.current) {
      if (animated) {
        setRect(props.rect);
        const timeout = setTimeout(() => {
          const rect = document.getElementById("video-modal")?.getRect();
          return setRect(rect);
        }, 10);
        return () => clearTimeout(timeout);
      } else {
        setRect(document.getElementById("video-modal")?.getRect());
      }
    }
  }, [props.rect, animated, fullscreen]);

  useEffect(() => {
    if (close) {
      if (animated) {
        setRect(props.rect);
        const timeout = setTimeout(
          () => dispatch(closeModal()),
          duration * 1000 + 100
        );
        return () => clearTimeout(timeout);
      } else {
        dispatch(closeModal());
      }
    }
  }, [animated, close, dispatch, props.rect]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={"center"}
        width={"100%"}
        height="100%"
        direction="row"
      >
        <Grid item onClick={preventDefault}>
          <Grid
            container
            height={"100%"}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <Center>
                <video
                  id="video-modal"
                  ref={fullPageRef}
                  crossOrigin="anonymous"
                  src={props.video}
                  style={{
                    ...style,
                    objectFit: "cover",
                    visibility: "hidden",
                  }}
                />
              </Center>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {rect && (
        <video
          onClick={preventDefault}
          ref={videoRef}
          crossOrigin="anonymous"
          src={props.video}
          autoPlay
          onEnded={onEnded}
          controls
          style={{
            transition:
              !transitionEnabled && !close
                ? undefined
                : `width ${duration}s linear,height ${duration}s linear,top ${duration}s linear,left ${duration}s linear`,
            top: rect.top,
            left: rect.left,
            position: "fixed",
            zIndex: ZIndexes.dialog,
            visibility: "visible",
            objectFit: "cover",
            width: rect.width,
            height: rect.height,
          }}
        />
      )}
    </>
  );
}

function ModalPDF(props: ModalPDFData & { rect: ModalDOMRect }) {
  const style = useFullScreenSize(props.rect);

  const images = useAsset(
    new Array(props.pages).fill(0).map((_, i) => `${props.pdf}${i}`)
  );
  const [imageIndex, setImageIndex] = useState(0);
  const image = useMemo(() => images[imageIndex], [images, imageIndex]);
  const fullPageRef = useRef<HTMLImageElement>(null);

  const [rect, setRect] = useState<ModalDOMRect | undefined>();
  const [show, setShow] = useState(false);

  const close = useAppSelector((state) => state.modal.close);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fullPageRef.current) {
      setRect(props.rect);
      setShow(false);
      const timeout = setTimeout(() => {
        setRect(fullPageRef.current!.getBoundingClientRect());
      }, 100);
      const timeout2 = setTimeout(() => setShow(true), duration * 1000 + 500);
      return () => {
        clearTimeout(timeout);
        clearTimeout(timeout2);
      };
    }
  }, [props.rect]);

  useEffect(() => {
    if (close) {
      dispatch(closeModal());
    }
  }, [close, dispatch, props.rect]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={"center"}
        width={"100%"}
        height="100%"
        direction="row"
      >
        <Grid item onClick={preventDefault}>
          <Box
            minWidth={80}
            onClick={
              imageIndex === 0 ? undefined : () => setImageIndex((e) => e - 1)
            }
            visibility={imageIndex >= 1 ? "visible" : "hidden"}
          >
            <Grid
              direction="column"
              container
              alignContent="center"
              justifyContent="center"
              height={"100%"}
            >
              <LeftIcon width={"1rem"} />
            </Grid>
          </Box>
        </Grid>
        <Grid item onClick={preventDefault}>
          <Grid
            container
            height={"100%"}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <Box>
                <TransformWrapper maxScale={maxZoomScale}>
                  <TransformComponent
                    wrapperStyle={{
                      overflow: "visible",
                    }}
                  >
                    <Grid
                      container
                      height={"100%"}
                      direction="row"
                      justifyContent="center"
                      alignContent="center"
                      gap={1}
                      visibility={show ? "visible" : "hidden"}
                    >
                      <Grid item height={"100%"}>
                        <img
                          ref={fullPageRef}
                          src={image}
                          alt={`Thumbnail`}
                          style={{
                            ...style,
                            objectFit: "cover",
                          }}
                        />
                        <Typography
                          textAlign="center"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          Pagina {imageIndex + 1} di {images.length}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TransformComponent>
                </TransformWrapper>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item onClick={preventDefault}>
          <Box
            minWidth={80}
            onClick={
              imageIndex >= images.length - 1
                ? undefined
                : () => setImageIndex((e) => e + 1)
            }
            visibility={imageIndex < images.length - 1 ? "visible" : "hidden"}
          >
            <Grid
              direction="column"
              container
              alignContent="center"
              justifyContent="center"
              height={"100%"}
            >
              <RightIcon width={"1rem"} />
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {rect && !show && (
        <img
          src={image}
          alt={`Thumbnail`}
          style={{
            transition: !transitionEnabled
              ? undefined
              : `width ${duration}s linear,height ${duration}s linear,top ${duration}s linear,left ${duration}s linear`,
            top: rect.top,
            left: rect.left,
            position: "fixed",
            zIndex: ZIndexes.dialog,
            visibility: "visible",
            width: rect.width,
            height: rect.height,
            objectFit: "cover",
          }}
        />
      )}
    </>
  );
}

export function preventDefault<T>(e: React.MouseEvent<T, MouseEvent>) {
  e.preventDefault();
  e.stopPropagation();
}

export { ModalSlider };

function VisibilityClickableArea() {
  const modal = useAppSelector((state) => state.modal);
  const [rect, setRect] = useState<Rect>();

  useEffect(() => {
    if (modal.id !== VISIBILITA_ID || !modal.open) {
      setRect(undefined);
      return;
    }
    const timeout = setTimeout(() => {
      const img = document.getElementById(VISIBILITA_ID);
      if (img !== null) {
        setRect(img.getRect());
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [modal]);

  if (rect !== undefined) {
    return (
      <div
        style={{
          ...rect,
          position: "absolute",
          zIndex: ZIndexes.everywhere + 1,
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Box position={"relative"} width={"100%"} height={"100%"}>
          <Box
            position={"absolute"}
            top={"20%"}
            left={"5%"}
            width={rect.width / 5.64}
            height={rect.height / 22.2}
            sx={{ opacity: 0.5, cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.merzaesthetics.it/", "_blank")
            }
          />
          <Box
            position={"absolute"}
            top={"20%"}
            left={"37%"}
            width={rect.width / 4.64}
            height={rect.height / 22.2}
            sx={{ opacity: 0.5, cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.centrimedicibelotero.it/", "_blank")
            }
          />
          <Box
            position={"absolute"}
            top={"20%"}
            left={"73%"}
            width={rect.width / 5.64}
            height={rect.height / 22.2}
            sx={{ opacity: 0.5, cursor: "pointer" }}
            onClick={() => window.open("https://www.tuame.it/", "_blank")}
          />
          <Box
            position={"absolute"}
            top={"66%"}
            left={"5%"}
            width={rect.width / 5.64}
            height={rect.height / 22.2}
            sx={{ opacity: 0.5, cursor: "pointer" }}
            onClick={() => window.open("https://www.ultherapy.it/", "_blank")}
          />
          <Box
            position={"absolute"}
            top={"66%"}
            left={"37%"}
            width={rect.width / 4.64}
            height={rect.height / 22.2}
            sx={{ opacity: 0.5, cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.centrimediciradiesse.it/", "_blank")
            }
          />
        </Box>
      </div>
    );
  } else {
    return <></>;
  }
}
