import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BeloteroSlider } from "../pages/prodotti/BeloteroPage";
import { CSSProperties } from "react";

export type ModalDOMRect = Pick<DOMRect, "height" | "width" | "top" | "left">;

interface ModalState {
  open: boolean;
  close: boolean;
  rect?: ModalDOMRect;
  modalData?: ModalData;
  animated?: boolean;
  fullscreen?: boolean;
  closeOnEnd?: boolean;
  id?: string;
}

export interface ModalImageData {
  type: "image";
  img: string;
}

export interface ModalVideoData {
  type: "video";
  video: string;
  style?: CSSProperties;
  useRect?: boolean;
}

export interface ModalPDFData {
  type: "pdf";
  pdf: string;
  pages: number;
}

export interface ModalBeforeAndAfterData {
  type: "beforeAndAfter";
  images: Array<string>;
}
export interface ModalSlidersData {
  type: "slider";
  sliders: Array<BeloteroSlider>;
}

export interface ModalCarouselData {
  type: "carousel";
  initial?: number;
  items: Array<{ type: "image" | "video"; src: string; style?: CSSProperties }>;
}

export type ModalData =
  | ModalImageData
  | ModalVideoData
  | ModalPDFData
  | ModalBeforeAndAfterData
  | ModalSlidersData
  | ModalCarouselData;

const initialState: ModalState = {
  open: false,
  close: true,
};

export type Rect = {
  height: number;
  width: number;
  top: number;
  left: number;
};
export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal(
      state,
      action: PayloadAction<{
        rect: Rect;
        id?: string;
        modalData: ModalData;
        animated?: boolean;
        fullscreen?: boolean;
        closeOnEnd?: boolean;
      }>
    ) {
      state.open = true;
      state.close = false;
      const { height, width, top, left } = action.payload.rect;
      state.rect = { height, width, top, left };
      state.id = action.payload.id;
      state.modalData = action.payload.modalData;
      state.animated = action.payload.animated ?? true;
      state.fullscreen = action.payload.fullscreen ?? false;
      state.closeOnEnd = true;
    },
    triggerClose(state) {
      state.open = true;
      state.close = true;
    },
    closeModal(state) {
      state.open = false;
      state.close = true;
      state.modalData = undefined;
      state.rect = undefined;
      state.closeOnEnd = false;
    },
  },
});

export const { openModal, closeModal, triggerClose } = modalSlice.actions;

export default modalSlice.reducer;

HTMLElement.prototype.getRect = function () {
  const { top, left, width, height } = this.getBoundingClientRect();
  return { top, left, width, height };
};
