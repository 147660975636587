import { AssetEntry } from "../components/AsssetsLoader";
import { Quality, getQuality } from "./assets";
import { isSafari } from "./functions";

enum AssetPriority {
  high = 2,
  medium = 1,
  low = 0,
  lowest = -1,
}

export const videos: (baseUrl: string) => Record<string, AssetEntry> = (
  baseUrl: string
) => ({
  chi_siamo_video: {
    path: `${baseUrl}/videos/home/chi_siamo.mp4`,
    type: "cacheBefore",
  },
  elite_video: {
    path: `${baseUrl}/videos/home/elite.mp4`,
    type: "cacheBefore",
  },
  i_nostri_prodotti_video: {
    path: `${baseUrl}/videos/home/i_nostri_prodotti.mp4`,
    type: "cacheBefore",
  },
  servizi_video: {
    path: `${baseUrl}/videos/home/servizi.mp4`,
    type: "cacheBefore",
  },
  i_nostri_prodotti_video_sfondo: {
    path: `${baseUrl}/videos/inostriprodotti/sfondo.mp4`,
  },
  sfondo: { path: `${baseUrl}/videos/sfondo_intro.mp4`, type: "cacheBefore" },
  standby_transition: {
    path: `${baseUrl}/videos/standby_transition.mp4`,
    type: "cacheBefore",
  },
  radiesse_video_intro: {
    path: `${baseUrl}/videos/radiesse/intro.mp4`,
    priority: AssetPriority.high,
    type: getQuality() === Quality.low ? undefined : "cacheBefore",
  },
  radiesse_video_tecnologia: {
    path: `${baseUrl}/videos/radiesse/Radiesse_Meccanismo_dazione.mp4`,
  },
  radiesse_timeline: {
    path: `${baseUrl}/videos/radiesse/TIMELINE_RADIESSE.mp4`,
  },
  radiesse_classic0: {
    path: `${baseUrl}/videos/radiesse/RADIESSE_CLASSIC/biostimolazione.mp4`,
    priority: AssetPriority.lowest,
    // type: "noCache",
  },
  radiesse_classic1: {
    path: `${baseUrl}/videos/radiesse/RADIESSE_CLASSIC/collo.mp4`,
    priority: AssetPriority.lowest,
  },
  radiesse_classic2: {
    path: `${baseUrl}/videos/radiesse/RADIESSE_CLASSIC/decollete_ago.mp4`,
    priority: AssetPriority.lowest,
  },
  radiesse_classic3: {
    path: `${baseUrl}/videos/radiesse/RADIESSE_CLASSIC/decollete_cannula.mp4`,
    priority: AssetPriority.lowest,
  },
  radiesse_classic4: {
    path: `${baseUrl}/videos/radiesse/RADIESSE_CLASSIC/mani_cannula.mp4`,
    priority: AssetPriority.lowest,
  },
  radiesse_lido0: {
    path: `${baseUrl}/videos/radiesse/RADIESSE_LIDOCAINE/angolo_mandibolare_rughe_marionetta_jawline.mp4`,
    priority: AssetPriority.lowest,
  },
  radiesse_lido1: {
    path: `${baseUrl}/videos/radiesse/RADIESSE_LIDOCAINE/mani_ago.mp4`,
    priority: AssetPriority.lowest,
  },
  radiesse_lido2: {
    path: `${baseUrl}/videos/radiesse/RADIESSE_LIDOCAINE/rughe_nasogeniene.mp4`,
    priority: AssetPriority.lowest,
  },
  radiesse_lido3: {
    path: `${baseUrl}/videos/radiesse/RADIESSE_LIDOCAINE/zigomatica_con_ago.mp4`,
    priority: AssetPriority.lowest,
  },
  radiesse_lido4: {
    path: `${baseUrl}/videos/radiesse/RADIESSE_LIDOCAINE/zigomatico_con_cannula.mp4`,
    priority: AssetPriority.lowest,
  },
  radiesse_siringhe: { path: `${baseUrl}/videos/radiesse/Siringa01.mp4` },
  belotero_slider_caroline: {
    path: `${baseUrl}/videos/belotero/slider_carolin.mp4`,
  },
  belotero_video_intro: {
    path: `${baseUrl}/videos/belotero/intro.mp4`,
    priority: AssetPriority.high,
    type: getQuality() === Quality.low ? undefined : "cacheBefore",
  },
  belotero_video_trattemento_revive: {
    path: `${baseUrl}/videos/belotero/Tunesi-Revive-SkinSaver.mp4`,
  },
  belotero_video_trattemento_lips: {
    path: `${baseUrl}/videos/belotero/Gigliofiorito-Lips.mp4`,
  },
  belotero_video_trattemento_balance: {
    path: `${baseUrl}/videos/belotero/Lanza-Balance.mp4`,
  },
  belotero_video_trattemento_intense: {
    path: `${baseUrl}/videos/belotero/Lazzarotto-Intense_V4.mp4`,
  },
  belotero_video_trattemento_volume: {
    path: `${baseUrl}/videos/belotero/Renzi-Volume.mp4`,
  },
  belotero_video_trattemento_soft: {
    path: `${baseUrl}/videos/belotero/Tunesi-Soft-Blanching.mp4`,
  },
  belotero_video_ba_carolin: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Carolin.mp4`,
  },
  belotero_video_ba_gordana: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Gordana.mp4`,
  },
  belotero_video_ba_jessica: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Jessica_Jung.mp4`,
  },
  belotero_video_ba_lidia: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Lidia.mp4`,
  },
  belotero_video_ba_marion: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Marion.mp4`,
  },
  belotero_video_ba_matthias: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Matthias.mp4`,
  },
  belotero_video_ba_milena: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Milena.mp4`,
  },
  belotero_video_ba_mirjana: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Mirjana.mp4`,
  },
  belotero_video_ba_weisser: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Sandra_Weisser.mp4`,
  },
  belotero_video_ba_sihan: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Siham.mp4`,
  },
  belotero_video_ba_simone: {
    path: `${baseUrl}/videos/belotero/Belotero-BA2021_SoMe_Slider_Simone.mp4`,
  },
  belotero_video_ba_sina: {
    path: `${baseUrl}/videos/belotero/Belotero21_SoMe_Slider_Sina.mp4`,
  },
  belotero_video_ba_solvita: {
    path: `${baseUrl}/videos/belotero/Belotero21_SoMe_Slider_Solvita.mp4`,
  },
  belotero_reologia: {
    path: `${baseUrl}/videos/belotero/Video_Reologia_BELOTERO.mp4`,
  },
  belotero_tecnologia: {
    path: `${baseUrl}/videos/belotero/Video_Tecnologia_BELOTERO.mp4`,
  },
  bocoture_purezza: { path: `${baseUrl}/videos/bocoture/purezza.mp4` },
  ultherapy_video_intro: {
    path: `${baseUrl}/videos/ultherapy/Video_Ultherapy_2020_V3.mp4`,
    priority: AssetPriority.high,
    type: getQuality() === Quality.low ? undefined : "cacheBefore",
  },
  ultherapy_banner_sup: {
    path: `${baseUrl}/videos/ultherapy/banner_superiore.mp4`,
  },
  ultherapy_banner_1: { path: `${baseUrl}/videos/ultherapy/banner_1.mp4` },
  ultherapy_banner_2: { path: `${baseUrl}/videos/ultherapy/banner_2.mp4` },
  ultherapy_collo: { path: `${baseUrl}/videos/ultherapy/collo.mp4` },
  ultherapy_decolte: { path: `${baseUrl}/videos/ultherapy/decolte.mp4` },
  ultherapy_viso: { path: `${baseUrl}/videos/ultherapy/viso.mp4` },
  ultherapy_method: {
    path: `${baseUrl}/videos/ultherapy/Ultherapy_Method_of_Action_Video.mp4`,
  },
  ultherapy_icons: {
    path: `${baseUrl}/videos/ultherapy/Ultherapy_Icons03.mov`,
    priority: AssetPriority.high,
  },
  ultherapy_icons_webm: {
    path: `${baseUrl}/videos/ultherapy/ultherapy_icons.webm`,
    priority: AssetPriority.high,
  },
  biorepeel_info: { path: `${baseUrl}/videos/biorepeel/tecnologia.mp4` },
  biorepeel_composizione: {
    path: `${baseUrl}/videos/biorepeel/composizione.mp4`,
  },
  biorepeel_come_si_applica0: {
    path: `${baseUrl}/videos/biorepeel/Come_si_applica.mp4`,
  },
  biorepeel_come_si_applica1: {
    path: `${baseUrl}/videos/biorepeel/Come_si_applica_2.mp4`,
  },
  biorepeel_addome: {
    path: `${baseUrl}/videos/biorepeel/addome.mp4`,
    priority: AssetPriority.lowest,
  },
  biorepeel_trattamenti1: {
    path: `${baseUrl}/videos/biorepeel/corso_paz_1_drcavallini.mp4`,
    priority: AssetPriority.lowest,
    // type: "noCache",
  },
  biorepeel_trattamenti2: {
    path: `${baseUrl}/videos/biorepeel/corso_paz_2_drcavallini.mp4`,
    priority: AssetPriority.lowest,
    // type: "noCache",
  },
  biorepeel_intro: {
    path: `${baseUrl}/videos/biorepeel/biorepeel_tessere.mov`,
    priority: AssetPriority.high,
  },
  biorepeel_intro_webm: {
    path: `${baseUrl}/videos/biorepeel/biorepeel_tessere.webm`,
    priority: AssetPriority.high,
  },
  biorepeel_scopri_di_piu: {
    path: `${baseUrl}/videos/biorepeel/Scopri_di_piu_post_trattamento.mp4`,
  },
  servizi_formazione: { path: `${baseUrl}/videos/servizi/FORMAZIONE.mp4` },
  servizi_marketing: {
    path: `${baseUrl}/videos/servizi/MARKETING_E_DIGITAL.mp4`,
  },
  servizi_eventi: { path: `${baseUrl}/videos/servizi/VIDEO_EVENTI.mp4` },
  servizi_medical: { path: `${baseUrl}/videos/servizi/MEDICAL_AFFAIRS.mp4` },
  servizi_app: { path: `${baseUrl}/videos/servizi/qr_code.mp4` },
  servizi_sfondo: { path: `${baseUrl}/videos/servizi/servizi_sfondo.mp4` },
  chi_siamo_sfondo: {
    path: `${baseUrl}/videos/chi_siamo/chi_siamo.mp4`,
  },
  chi_siamo_1: { path: `${baseUrl}/videos/chi_siamo/chi_siamo_1.mp4` },
  why_merz: { path: `${baseUrl}/videos/chi_siamo/why_merz.mp4` },
  valori_merz: { path: `${baseUrl}/videos/chi_siamo/valori_merz.mp4` },
  viso_muscles: {
    path: `${baseUrl}/videos/viso/muscles.mov`,
    priority: isSafari() ? AssetPriority.high : undefined,
    type: isSafari() ? "cacheBefore" : undefined,
  },
  viso_muscles_webm: {
    path: `${baseUrl}/videos/viso/muscles.webm`,
    priority: !isSafari() ? AssetPriority.high : undefined,
    type: !isSafari() ? "cacheBefore" : undefined,
  },
  elite_sfondo: { path: `${baseUrl}/videos/elite/sfondo.mp4` },
  elite_joint_sfondo: { path: `${baseUrl}/videos/elite/joint.mp4` },
  elite_advanced_training: {
    path: `${baseUrl}/videos/elite/ADVANCED_TRAINING.mp4`,
  },
  elite_imclass_video_1: { path: `${baseUrl}/videos/elite/imcass/dubai.mp4` },
  elite_imclass_video_2: {
    path: `${baseUrl}/videos/elite/imcass/aftermovie.mp4`,
  },
  elite_imexs_video_1: {
    path: `${baseUrl}/videos/elite/imexs/vienna_2022.mp4`,
  },
  elite_itmexs_video_1: { path: `${baseUrl}/videos/elite/mexs/summit.mp4` },
  elite_amcw_video_1: { path: `${baseUrl}/videos/elite/amwc/highlight.mp4` },
  elite_imcas_video_1: {
    path: `${baseUrl}/videos/elite/imcas/highlight.mp4`,
  },
  elite_face_to_face: {
    path: `${baseUrl}/videos/elite/OneToOne_Lab_Inietta_Bella.mp4`,
  },
  elite_in_clinic: { path: `${baseUrl}/videos/elite/IN_CLINIC.mp4` },
  elite_digital_marketing: {
    path: `${baseUrl}/videos/elite/DIGITAL_MARKETING.mp4`,
  },
  elite_content_creation_video: {
    path: `${baseUrl}/videos/elite/CONTENT_CREATION.mp4`,
    priority: AssetPriority.high,
  },
  elite_scientific_network: {
    path: `${baseUrl}/videos/elite/SCIENTIFIC_NETWORK.mp4`,
  },
  elite_merz_care: {
    path: `${baseUrl}/videos/elite/schermata2_4/merz_care.mp4`,
  },
  elite_innovation_forum: {
    path: `${baseUrl}/videos/elite/schermata2_4/innovation_forum_2022.mp4`,
  },
  elite_joint_business_plan: {
    path: `${baseUrl}/videos/elite/JOINT_BUSINESS_PLAN.mp4`,
  },
});

export type VideoKeys = string;
