import { LayerSelection, SelectionPoint } from "../pages/prodotti/VisoPage";
import { ImageKeys } from "../utils/assets";
import { Button } from "../components/Atoms/Lines";

interface CreateExplorerProps {
  element?: JSX.Element;
}
export enum Prodotto {
  BELOTERO_BALANCE = "Belotero Balance",
  BELOTERO_SOFT = "Belotero Soft",
  BELOTERO_INTENSE = "Belotero Intense",
  BELOTERO_REVIVE = "Belotero Revive",
  BELOTERO_VOLUME = "Belotero Volume",
  BELOTERO_LIPS_CONTOUR = "Belotero Lips Contour",
  BELOTERO_LIPS_SHAPE = "Belotero Lips Shape",
  BIOREPEELCL3_BODY = "BioRePeelCl3 - Body",
  BIOREPEELCL3_FND = "BioRePeelCl3 - FND",
  BOCOUTURE = "Bocouture",
  RADIESSE_PLUS = "Radiesse+",
  RADIESSE_PLUS_DILUITO = "Radiesse+/Radiesse DILUITO",
  RADIESSE_DILUITO = "Radiesse DILUITO",
  ULTHERAPY = "Ultherapy",
}

export const layerDX = 0;
export const layerCentral = 21;
export const layerSX = 40;

const terzo = {
  scale: 1.3,
  translateY: 8,
};

export interface ExplorerProps {
  explore?: () => void;
  goBack?: () => void;
}

export const createExplorer =
  (props: CreateExplorerProps) =>
  ({ explore, goBack }: ExplorerProps) => {
    return (
      <>
        {props?.element}
        <Button color="debianRed" variant="contained" onClick={explore}>
          Conferma
        </Button>
        <Button color="warning" variant="outlined" onClick={goBack}>
          CHIUDI
        </Button>
      </>
    );
  };

const viso: SelectionPoint = {
  desc: {
    distretto: "Viso",
    showAreaName: false,
    obiettivi: [
      {
        obiettivo: "Biorivitalizzazione/Skin quality",
        prodotto: Prodotto.BIOREPEELCL3_FND,
        ago: "",
        cannula: "",
        tecnica:
          "Frizione con guanto in nitrile fino a completo assorbimento; usare 1,5-2 ml; lasciare in posa fino a 5 minuti in funzione del biotipo cutaneo",
        piano: "NO INIEZIONE: uso topico",
      },
    ],
  },
  point: {
    [layerCentral]: {
      image: "viso_punto",
      clipPath: {
        type: "circle",
        center: {
          x: 50,
          y: 50,
        },
        size: 350,
      },
      position: {
        x: 32,
        y: 50,
      },
    },
    [layerDX]: {
      image: "viso_dx",
      clipPath: {
        type: "circle",
        center: {
          x: 45,
          y: 50,
        },
        size: 350,
      },
      position: {
        x: 32,
        y: 50,
      },
    },
    [layerSX]: {
      image: "viso_sx",
      clipPath: {
        type: "circle",
        center: {
          x: 60,
          y: 50,
        },
        size: 350,
      },
      position: {
        x: 32,
        y: 50,
      },
    },
  },
};
export const layersData: Partial<Record<ImageKeys, LayerSelection>> = {
  mano: {
    textBox: {
      x: 3,
      y: 80,
    },
    name: "Mani",
    selectionPoints: [
      //mani_punto
      {
        desc: {
          showAreaName: false,
          distretto: "Mani",
          obiettivi: [
            {
              obiettivo: "Biorivitalizzazione/Skin Quality",
              prodotto: Prodotto.BIOREPEELCL3_BODY,
              ago: "",
              cannula: "",
              tecnica:
                "Frizione con guanto in nitrile fino a completo assorbimento; usare 1-2 ml; lasciare in posa 3-12 minuti in funzione del biotipo cutaneo",
              piano: "NO INIEZIONE: uso topico",
            },
            {
              obiettivo: "Biostimolazione/Skin Quality",
              prodotto: Prodotto.RADIESSE_PLUS_DILUITO,
              ago: "27 G",
              cannula: "22-25 G",
              tecnica: "Microboli, Lineare retrograda ventaglio",
              piano: "Subdermico",
              image: ["immagini_viso_radi8", "immagini_viso_radi9"],
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "mani_punto",
            clipPath: {
              type: "circle",
              center: {
                x: 35,
                y: 90.61,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "mani_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 47,
                y: 93.61,
              },
              size: 200,
            },
          },
        },
      },
    ],
    layer: {
      [layerCentral]: "mano",
      [layerDX]: "area_dx_mano",
      [layerSX]: "area_sx_mano",
    },
  },
  decolte: {
    name: "Décolleté",
    textBox: {
      x: 57.03,
      y: 82.73,
    },
    selectionPoints: [
      //decollette_punto
      {
        desc: {
          distretto: "Décolletté",
          showAreaName: false,
          obiettivi: [
            {
              obiettivo: "Biorivitalizzazione/Skin Quality",
              prodotto: Prodotto.BIOREPEELCL3_FND,
              ago: "",
              cannula: "",
              tecnica:
                "Frizione con guanto in nitrile fino a completo assorbimento; usare 2 ml; lasciare in posa fino a 5 minuti in funzione del biotipo cutaneo",
              piano: "NO INIEZIONE: uso topico",
            },
            {
              obiettivo: "Lassità Cutanea/Skin Tightening",
              prodotto: Prodotto.ULTHERAPY,
              ago: "",
              cannula: "",
              tecnica:
                "Ultrasuoni micofocalizzati per generare TCP (punti di coagulazione termica)",
              piano: "NO INIEZIONE: 1,5-3-4,5 mm di profondità",
            },
            {
              obiettivo: "Biostimolazione/Skin Quality",
              prodotto: Prodotto.RADIESSE_DILUITO,
              ago: "27 G",
              cannula: "22-25 G",
              tecnica: "Lineare retrograda, ventaglio",
              piano: "Subdermico",
              image: "immagini_viso_radi3",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "decollete_punto",
            clipPath: {
              type: "circle",
              center: {
                x: 50.45,
                y: 95.2,
              },
              size: 300,
            },
          },
          [layerDX]: {
            image: "decollete_dx",
            clipPath: {
              type: "circle",
              center: {
                x: 45.45,
                y: 95.2,
              },
              size: 300,
            },
          },
          [layerSX]: {
            image: "decollete_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 59.45,
                y: 95.2,
              },
              size: 300,
            },
          },
        },
      },
    ],
    layer: {
      [layerCentral]: "decolte",
      [layerDX]: "area_dx_decollete",
      [layerSX]: "area_sx_decollete",
    },
  },
  collo: {
    name: "Collo",
    textBox: {
      x: 3,
      y: 70,
    },
    selectionPoints: [
      //collo_punto
      {
        desc: {
          showAreaName: false,
          distretto: "Collo",
          obiettivi: [
            {
              obiettivo: "Biorivitalizzazione/Skin Quality",
              prodotto: Prodotto.BIOREPEELCL3_FND,
              ago: "",
              cannula: "",
              tecnica:
                "Frizione con guanto in nitrile fino a completo assorbimento; usare 2 ml; lasciare in posa fino a 5 minuti in funzione del biotipo cutaneo",
              piano: "NO INIEZIONE: uso topico",
            },
            {
              obiettivo: "Lassità Cutanea/Skin Tightening",
              prodotto: Prodotto.ULTHERAPY,
              ago: "",
              cannula: "",
              tecnica:
                "Ultrasuoni micofocalizzati per generare TCP (punti di coagulazione termica)",
              piano: "NO INIEZIONE: 1,5-3-4,5 mm di profondità",
            },
            {
              obiettivo: "Biostimolazione/Skin Quality",
              prodotto: Prodotto.RADIESSE_DILUITO,
              ago: "",
              cannula: "22-25 G",
              tecnica: "Lineare retrograda, ventaglio",
              piano: "Subdermico",
              image: "immagini_viso_radi2",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "collo_punto",
            clipPath: {
              type: "circle",
              center: {
                x: 50.45,
                y: 72.23,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "collo_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 50.45,
                y: 72.23,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "collo_dx",
            clipPath: {
              type: "circle",
              center: {
                x: 50.45,
                y: 72.23,
              },
              size: 200,
            },
          },
        },
      },
    ],
    layer: {
      [layerCentral]: "collo",
      [layerDX]: "area_dx_collo",
      [layerSX]: "area_sx_collo",
    },
  },
  sottomento: {
    textBox: {
      x: 3,
      y: 60,
    },
    layer: {
      [layerCentral]: "sottomento",
      [layerDX]: "area_dx_sottomento",
      [layerSX]: "area_sx_sottomento",
    },
    name: "Sottomento",
    selectionPoints: [
      //sottomento_punto
      {
        desc: {
          showAreaName: false,
          distretto: "Sottomento",
          obiettivi: [
            {
              obiettivo: "Lassità Cutanea/Skin Tightening",
              prodotto: Prodotto.ULTHERAPY,
              ago: "",
              cannula: "",
              tecnica:
                "Ultrasuoni micofocalizzati per generare TCP (punti di coagulazione termica)",
              piano: "NO INIEZIONE: 1,5-3-4,5 mm di profondità",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "sottomento_punto",
            clipPath: {
              type: "circle",
              center: {
                x: 50.25,
                y: 69.2,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "sottomento_dx",
            clipPath: {
              type: "circle",
              center: {
                x: 44.25,
                y: 68.2,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "sottomento_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 54.25,
                y: 68.2,
              },
              size: 200,
            },
          },
        },
      },
    ],
  },
  ti: {
    name: "Terzo inferiore",
    textBox: {
      x: 71.1,
      y: 59.25,
    },
    ...terzo,
    selectionPoints: [
      viso,
      //ti TODO LINE
      {
        desc: {
          distretto: "Terzo inferiore",
          showAreaName: false,
          obiettivi: [
            {
              obiettivo: "Lassità cutanea/ skin tightening",
              prodotto: Prodotto.ULTHERAPY,
              ago: "",
              cannula: "",
              tecnica:
                "Ultrasuoni micofocalizzati per generare TCP (punti di coagulazione termica)",
              piano: "NO INIEZIONE: 1,5-3-4,5 mm di profondità",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ti_punto",
            clipPath: {
              type: "circle",
              center: {
                x: 50,
                y: 62.5,
              },
              size: 300,
            },
            position: {
              x: 30,
              y: 59.25,
            },
          },
          [layerDX]: {
            image: "ti_dx",
            clipPath: {
              type: "circle",
              center: {
                x: 50,
                y: 62.5,
              },
              size: 300,
            },
            position: {
              x: 30,
              y: 59.25,
            },
          },
          [layerSX]: {
            image: "ti_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 50,
                y: 62.5,
              },
              size: 300,
            },
            position: {
              x: 30,
              y: 59.25,
            },
          },
        },
      },

      //ti_mento
      {
        desc: {
          distretto: "Mento",
          obiettivi: [
            {
              obiettivo: "Proiezione",
              prodotto: Prodotto.BELOTERO_VOLUME,
              ago: "27-30 G",
              cannula: "",
              tecnica: "Bolo",
              piano: "Sovraperiosteo",
              image: "immagini_viso_belo9",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ti_mento",
            clipPath: {
              type: "circle",
              center: {
                x: 50.45,
                y: 65,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "mento_dx",
            clipPath: {
              type: "circle",
              center: {
                x: 38,
                y: 65,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "mento_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 61.5,
                y: 65,
              },
              size: 200,
            },
          },
        },
      },
      //ti_jawline
      {
        desc: {
          distretto: "Jawline",
          obiettivi: [
            {
              obiettivo: "Ridefinizione/Contouring",
              prodotto: Prodotto.RADIESSE_PLUS,
              ago: "",
              cannula: "22-25 G",
              tecnica: "Lineare retrograda",
              piano: "Subdermico",
              image: "immagini_viso_radi7",
            },
            {
              obiettivo: "Ridefinizione/Contouring",
              prodotto: Prodotto.BELOTERO_VOLUME,
              ago: "",
              cannula: "25 G",
              tecnica: "Lineare retrograda, Bolo",
              piano: "Sottocutaneo",
              image: "immagini_viso_belo4",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ti_jawline",
            clipPath: {
              type: "circle",
              center: {
                x: 57,
                y: 63,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "ti_jawline_lato",
            clipPath: {
              type: "circle",
              center: {
                x: 48.5,
                y: 64.3,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "jawline_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 50,
                y: 63,
              },
              size: 200,
            },
          },
        },
      },
      //ti_prejowl
      {
        desc: {
          distretto: "Pre-jowl",
          obiettivi: [
            {
              obiettivo: "Riempimento",
              prodotto: Prodotto.BELOTERO_VOLUME,
              ago: "27-30 G",
              cannula: "25 G",
              tecnica: "Lineare retrograda, Bolo",
              piano: "Sottocutaneo",
              image: "immagini_viso_belo10",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ti_pre_jowl",
            clipPath: {
              type: "circle",
              center: {
                x: 55,
                y: 65.5,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "ti_pre_jowl_lato",
            clipPath: {
              type: "circle",
              center: {
                x: 43.5,
                y: 66,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "prejowl_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 55,
                y: 65.5,
              },
              size: 200,
            },
          },
        },
      },
      //ti_ruga_mentolabiale
      {
        desc: {
          distretto: "Ruga mentolabiale",
          obiettivi: [
            {
              obiettivo: "Riempimento",
              prodotto: Prodotto.BELOTERO_INTENSE,
              ago: "27 G",
              cannula: "25 G",
              tecnica: "Lineare retrograda, Bolo",
              piano: "Derma profondo",
              image: "immagini_viso_belo11",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ti_mento",
            clipPath: {
              type: "circle",
              center: {
                x: 50.45,
                y: 62.5,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "mento_dx",
            clipPath: {
              type: "circle",
              center: {
                x: 38,
                y: 61,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "mento_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 61.5,
                y: 61,
              },
              size: 200,
            },
          },
        },
      },
      //ti_ruga_della_marionetta
      {
        desc: {
          distretto: "Rughe della marionetta",
          obiettivi: [
            {
              obiettivo: "Riempimento",
              prodotto: Prodotto.BELOTERO_INTENSE,
              ago: "27 G",
              cannula: "25 G",
              tecnica: "Lineare retrograda, bolo",
              piano: "Derma profondo",
              image: "immagini_viso_belo12",
            },
            {
              obiettivo: "Riempimento",
              prodotto: Prodotto.RADIESSE_PLUS,
              ago: "",
              cannula: "22-25 G",
              tecnica: "Lineare retrograda",
              piano: "Subdermico",
              image: "immagini_viso_radi10",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ti_rughe_marionetta",
            clipPath: {
              type: "circle",
              center: {
                x: 55,
                y: 61,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "ti_rughe_marionetta_lato",
            clipPath: {
              type: "circle",
              center: {
                x: 45,
                y: 60,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "marionetta_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 55,
                y: 60,
              },
              size: 200,
            },
          },
        },
      },
      //ti_angolo_mandibolare
      {
        desc: {
          distretto: "Angolo Mandibolare",
          obiettivi: [
            {
              obiettivo: "Ridefinizione/Contouring",
              prodotto: Prodotto.RADIESSE_PLUS,
              ago: "27 G",
              cannula: "",
              tecnica: "Bolo",
              piano: "Sovraperiosteo",
              image: "immagini_viso_radi1",
            },
            {
              obiettivo: "Ridefinizione/Contouring",
              prodotto: Prodotto.BELOTERO_VOLUME,
              ago: "27-30 G",
              cannula: "",
              tecnica: "Bolo",
              piano: "Sovraperiosteo",
              image: "immagini_viso_belo1",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ti_angolo_mandibolare",
            clipPath: {
              type: "circle",
              center: {
                x: 58.76,
                y: 58.37,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "ti_angolo_mandibolare_lato",
            clipPath: {
              type: "circle",
              center: {
                x: 52,
                y: 57,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "angolo_mandibolare_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 45,
                y: 56,
              },
              size: 200,
            },
          },
        },
      },
      //ti_labbra
      {
        desc: {
          distretto: "Labbra",
          obiettivi: [
            {
              obiettivo: "Rimodellamento",
              prodotto: Prodotto.BELOTERO_LIPS_SHAPE,
              ago: "27 G",
              cannula: "25-27 G",
              tecnica: "Lineare retrograda, Bolo",
              piano: "Sottomucoso labiale",
              image: "immagini_viso_belo8",
            },
            {
              obiettivo: "Proiezione",
              prodotto: Prodotto.BELOTERO_INTENSE,
              ago: "27 G",
              cannula: "25 G",
              tecnica: "Lineare retrograda, Bolo",
              piano: "Sottomucoso labiale",
              image: "immagini_viso_belo6",
            },
            {
              obiettivo: "Ridefinizione",
              prodotto: Prodotto.BELOTERO_LIPS_CONTOUR,
              ago: "27-30 G",
              cannula: "25-27 G",
              tecnica: "Blanching, Lineare retrograda",
              piano: "Derma superficiale o medio",
              image: "immagini_viso_belo7",
            },
            {
              obiettivo: "Correzione",
              prodotto: Prodotto.BELOTERO_BALANCE,
              ago: "27-30 G",
              cannula: "25-27 G",
              tecnica: "Blanching, Lineare retrograda",
              piano: "Derma superficiale o medio",
              image: "immagini_viso_belo5",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ti_labbra",
            clipPath: {
              type: "circle",
              center: {
                x: 50.45,
                y: 58.5,
              },
              size: 200,
            },
          },
        },
      },
    ],
    layer: {
      [layerCentral]: "ti",
      [layerDX]: "area_dx_ti",
      [layerSX]: "area_sx_ti",
    },
  },
  tm: {
    ...terzo,
    name: "Terzo medio",
    textBox: {
      x: 71.1,
      y: 47,
    },
    selectionPoints: [
      viso,
      //tm_solco_lacrimale
      {
        desc: {
          distretto: "Solco lacrimale",
          obiettivi: [
            {
              obiettivo: "Riempimento",
              prodotto: Prodotto.BELOTERO_BALANCE,
              ago: "30 G",
              cannula: "25 G",
              tecnica: "Lineare retrogada, Bolo",
              piano: "Sovraperiosteo",
              image: "immagini_viso_belo17",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "tm_solco_lacrimale",
            clipPath: {
              type: "circle",
              center: {
                x: 44.65,
                y: 46,
              },
              size: 200,
            },
          },
          //TODO: POINT NOT WELL POSITIONED
          [layerSX]: {
            image: "tm_solco_lacrimale_lato",
            clipPath: {
              type: "circle",
              center: {
                x: 56.65,
                y: 45.5,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "solco_lacrimale_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 44.65,
                y: 46,
              },
              size: 200,
            },
          },
        },
      },
      //tm_rughe_periorali
      {
        desc: {
          distretto: "Rughe periorali",
          obiettivi: [
            {
              obiettivo: "Distensione",
              prodotto: Prodotto.BELOTERO_SOFT,
              ago: "30 G",
              cannula: "",
              tecnica: "Blanching",
              piano: "Derma superficiale o medio",
              image: "immagini_viso_belo15",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "tm_rughe_periorali",
            clipPath: {
              type: "circle",
              center: {
                x: 48.15,
                y: 55.83,
              },
              size: 200,
            },
          },
        },
      },
      // tm_filtro
      {
        desc: {
          distretto: "Filtro",
          obiettivi: [
            {
              obiettivo: "Riempimento",
              prodotto: Prodotto.BELOTERO_INTENSE,
              ago: "27 G",
              cannula: "",
              tecnica: "Lineare retrograda",
              piano: "Derma profondo",
              image: "immagini_viso_belo2",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "tm_filtro",
            clipPath: {
              type: "circle",
              center: {
                x: 50.29,
                y: 54.31,
              },
              size: 200,
            },
          },
        },
      },
      //tm_nasolabiali
      {
        desc: [
          {
            distretto: "Rughe nasolabiali",
            distrettoOption: "Profonde",
            obiettivi: [
              //tm_nasolabiali_profonde
              {
                obiettivo: "Riempimento",
                prodotto: Prodotto.BELOTERO_INTENSE,
                ago: "27 G",
                cannula: "25 G",
                tecnica: "Lineare retrogada, Bolo",
                piano: "Derma profondo",
                image: "immagini_viso_belo14",
              },
            ],
          },
          {
            distretto: "Rughe nasolabiali",
            distrettoOption: "Lievi",
            obiettivi: [
              //tm_nasolabiali_lievi
              {
                obiettivo: "Riempimento",
                prodotto: Prodotto.BELOTERO_BALANCE,
                ago: "27-30 G",
                cannula: "",
                tecnica: "Blanching, Lineare retrograda",
                piano: "Derma superficiale o medio",
                image: "immagini_viso_belo12",
              },
            ],
          },
        ],
        point: {
          [layerCentral]: {
            image: "tm_nasolabiali",
            clipPath: {
              type: "circle",
              center: {
                x: 45.64,
                y: 54.2,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "tm_nasolabiali_lato",
            clipPath: {
              type: "circle",
              center: {
                x: 56,
                y: 53,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "nasolabiali_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 45.64,
                y: 54.2,
              },
              size: 200,
            },
          },
        },
      },
      //tm_zigomi
      {
        desc: {
          distretto: "Zigomi",
          obiettivi: [
            {
              obiettivo: "Volumizzazione",
              prodotto: Prodotto.BELOTERO_VOLUME,
              ago: "27-30 G",
              cannula: "21-25-27 G",
              tecnica: "Lineare retrograda, Bolo",
              piano: "Derma profondo, Sottocutaneo",
              image: "immagini_viso_belo20",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "tm_zigomi",
            clipPath: {
              type: "circle",
              center: {
                x: 41.62,
                y: 50,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "tm_zigomi_lato",
            clipPath: {
              type: "circle",
              center: {
                x: 53,
                y: 48,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "zigomi_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 41.62,
                y: 50,
              },
              size: 200,
            },
          },
        },
      },
      //tm_guance
      {
        desc: {
          distretto: "Guance",
          obiettivi: [
            {
              obiettivo: "Biorivitalizzazione/Skin quality",
              prodotto: Prodotto.BELOTERO_REVIVE,
              ago: "30 G",
              tecnica: "Microboli",
              piano: "Derma intermedio, profondo",
              image: "immagini_viso_belo3",
            },
            {
              obiettivo: "Biostimolazione/Skin Quality",
              prodotto: Prodotto.RADIESSE_DILUITO,
              ago: "",
              cannula: "22-25G",
              tecnica: "Lineare retrograda, ventaglio",
              piano: "Subdermico",
              image: [
                "immagini_viso_radi4",
                "immagini_viso_radi5",
                "immagini_viso_radi6",
              ],
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "tm_guance",
            clipPath: {
              type: "circle",
              center: {
                x: 42.86,
                y: 53.9,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "tm_guance_lato",
            clipPath: {
              type: "circle",
              center: {
                x: 52,
                y: 56,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "guance_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 42.86,
                y: 53.9,
              },
              size: 200,
            },
          },
        },
      },
      //tm
      {
        desc: {
          distretto: "Terzo medio",
          showAreaName: false,
          obiettivi: [
            {
              obiettivo: "Lassità cutanea/ skin tightening",
              prodotto: Prodotto.ULTHERAPY,
              ago: "",
              tecnica:
                "Ultrasuoni micofocalizzati per generare TCP (punti di coagulazione termica)",
              piano: "NO INIEZIONE: 1,5-3-4,5 mm di profondità",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "tm_punto",
            clipPath: {
              type: "circle",
              center: {
                x: 50,
                y: 50,
              },
              size: 300,
            },
            position: {
              x: 30,
              y: 47,
            },
          },
          [layerDX]: {
            image: "tm_dx",
            clipPath: {
              type: "circle",
              center: {
                x: 45,
                y: 50,
              },
              size: 300,
            },
            position: {
              x: 30,
              y: 47,
            },
          },
          [layerSX]: {
            image: "tm_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 60,
                y: 50,
              },
              size: 300,
            },
            position: {
              x: 30,
              y: 47,
            },
          },
        },
      },
    ],
    layer: {
      [layerCentral]: "tm",
      [layerDX]: "area_dx_tm",
      [layerSX]: "area_sx_tm",
    },
  },
  ts: {
    ...terzo,
    name: "Terzo superiore",
    textBox: {
      x: 71.1,
      y: 35.1,
    },
    selectionPoints: [
      viso,
      //ts
      {
        desc: {
          distretto: "Terzo superiore",
          obiettivi: [
            {
              obiettivo: "Lassità cutanea/ skin tightening",
              prodotto: Prodotto.ULTHERAPY,
              ago: "",
              cannula: "",
              tecnica:
                "Ultrasuoni micofocalizzati per generare TCP (punti di coagulazione termica)",
              piano: "NO INIEZIONE: 1,5-3-4,5 mm di profondità",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ts_punto",
            clipPath: {
              type: "circle",
              center: {
                x: 50,
                y: 38,
              },
              size: 300,
            },
            position: {
              x: 30,
              y: 35.1,
            },
          },
          [layerDX]: {
            image: "ts_punto",
            clipPath: {
              type: "circle",
              center: {
                x: 50,
                y: 38,
              },
              size: 300,
            },
            position: {
              x: 30,
              y: 35.1,
            },
          },
          [layerSX]: {
            image: "ts_punto",
            clipPath: {
              type: "circle",
              center: {
                x: 50,
                y: 38,
              },
              size: 300,
            },
            position: {
              x: 30,
              y: 35.1,
            },
          },
        },
      },
      //ts_rughe_frontali
      {
        desc: {
          distretto: "Rughe della fronte",
          obiettivi: [
            {
              obiettivo: "Distensione",
              prodotto: Prodotto.BOCOUTURE,
              ago: "30-33 G",
              cannula: "",
              tecnica: "5 punti di iniezione, da 10 a 20 unità TOTALI",
              piano: "Intramuscolo, Muscolo frontale",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ts_rughe_frontali",
            clipPath: {
              type: "circle",
              center: {
                x: 50.3,
                y: 32,
              },
              size: 200,
            },
          },
        },
      },
      //ts_rughe_glabellari
      {
        desc: {
          distretto: "Rughe glabellari",
          obiettivi: [
            {
              obiettivo: "Distensione",
              prodotto: Prodotto.BOCOUTURE,
              ago: "30-33 G",
              cannula: "",
              tecnica: "5 punti di iniezione da 4 unità, 20 unità TOTALI",
              piano:
                "Intramuscolo; muscolo corrugatore (2 iniezioni per emilato), muscolo procero (una iniezione)",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ts_rughe_glablellari",
            clipPath: {
              type: "circle",
              center: {
                x: 50.3,
                y: 39,
              },
              size: 200,
            },
          },
        },
      },
      //ts_tempie
      {
        desc: [
          {
            distretto: "Tempie",
            distrettoOption: "Anteriori e posteriori",
            obiettivi: [
              //ts_tempie_anteriori_e_posteriori
              {
                obiettivo: "Riempimento",
                prodotto: Prodotto.BELOTERO_VOLUME,
                ago: "",
                cannula: "22 G",
                tecnica: "Lineare retrograda",
                piano: "Subdermico, interfasciale",
                image: "immagini_viso_belo19",
              },
            ],
          },
          {
            distretto: "Tempie",
            distrettoOption: "Anteriori, grave e svuotamento",
            obiettivi: [
              //ts_tempie_anteriori_grave_svuotamento
              {
                obiettivo: "Riempimento",
                prodotto: Prodotto.BELOTERO_VOLUME,
                ago: "27-30 G",
                cannula: "",
                tecnica: "Microboli",
                piano: "Sovraperiosteo",
                image: "immagini_viso_belo18",
              },
            ],
          },
        ],
        point: {
          [layerCentral]: {
            image: "ts_tempie",
            clipPath: {
              type: "circle",
              center: {
                x: 60.75,
                y: 39,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "ts_tempie_lato",
            clipPath: {
              type: "circle",
              center: {
                x: 50,
                y: 38.5,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "tempie_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 50,
                y: 39,
              },
              size: 200,
            },
          },
        },
      },
      //ts_periorbitali
      {
        desc: {
          distretto: "Rughe periorbitali",
          obiettivi: [
            {
              obiettivo: "Distensione",
              prodotto: Prodotto.BOCOUTURE,
              ago: "30-33 G",
              cannula: "",
              tecnica:
                "3 punti di iniezione per emilato, 24 unità TOTALI (4 unità per punto di iniezione)",
              piano: "Intramuscolo; muscolo orbicolare dell'occhio ",
            },
            {
              obiettivo: "Distensione",
              prodotto: Prodotto.BELOTERO_SOFT,
              ago: "30 G",
              cannula: "",
              tecnica: "Blanching",
              piano: "Intradermico",
              image: "immagini_viso_belo16",
            },
          ],
        },
        point: {
          [layerCentral]: {
            image: "ts_periorbitali",
            clipPath: {
              type: "circle",
              center: {
                x: 58.97,
                y: 43,
              },
              size: 200,
            },
          },
          [layerDX]: {
            image: "ts_periorbitali_lato",
            clipPath: {
              type: "circle",
              center: {
                x: 50.5,
                y: 43,
              },
              size: 200,
            },
          },
          [layerSX]: {
            image: "periorbital_sx",
            clipPath: {
              type: "circle",
              center: {
                x: 50.5,
                y: 43,
              },
              size: 200,
            },
          },
        },
      },
    ],
    layer: {
      [layerCentral]: "ts",
      [layerDX]: "area_dx_ts",
      [layerSX]: "area_sx_ts",
    },
  },
};
